.home{
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: center;
  height: 100vh;
  overflow: hidden;
  position: relative;
  background-size: cover;
  background-attachment: fixed;
  background-image:linear-gradient(rgba(230,20,20,.7), rgba(10,20,20,.7)),url('../../../../assets/mo-abdalmagid/63-General-Physics-1-1.jpg')!important;
}
@keyframes img {
  0%{
      transform: rotate(10deg);
    }
    50%{
    transform: rotate(-10deg);
  }
  100%{
    transform: rotate(10deg);

  }
}

.home .home-right{
  width:50%;
  margin: auto;
  text-align: center;
  z-index:10;
  color: aliceblue!important;
}
.home .home-right h1{
  font-size:45px;
  font-weight:800;
  line-height:1.9;
  margin-top: 120px;
  color: white;
  animation:title 0s 3s infinite;
}
@keyframes title {
  0%{
      transform: scale(1);
    }
    50%{
      transform: scale(1.05);
    }
    100%{
      transform: scale(1);
  }

}
.home .home-right p{
color: rgb(214, 213, 213);
width: 100%;
font-size:23px;
}
.home .home-left{
  width:35%;
  margin-left: 30px;
  margin-top: 55px;
  animation: img 3s 0s infinite;
}
.home-left img{
  width: 100%;
}
 .atoms{
  position: absolute;
  width: 100%;
  top:70px;
  display: flex;
  justify-content:space-around;
}
 .atom{
font-size: 60px;
color: #ef485c;
}
 .one{
  animation: atom 3s 0s infinite;
}
 .two{
  animation: atom 4s 0s infinite;
}
 .three{
  animation: atom 3s 0s infinite;
}
 .four{
  animation: atom 4s 0s infinite;
}
@keyframes atom {
  0%{
      margin-top: 0px;
      transform: rotate(180deg);
  }
  50%{
      transform: rotate(270deg);
      margin-top: 450px;
  }
  100%{
      transform: rotate(360deg);
      margin-top: 0px;
  }
}