:root {
    --border-color: #FFFFFFB2;
}

/* start global rules */
a,
a:hover {
    all: unset;
    cursor: pointer;
}

* {
    direction: rtl;
}

/* end global rules */
/* start dashboard layout content container */
.dashboard-layout-content-container {
    display: flex;
    padding: 15px 10px;
}

/* end dashboard layout content container */
/* start header */
header.dashboard-header {
    padding: 15px 0;
    background-color: #FBFBFBB2;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

header.dashboard-header>div {
    display: flex;
    align-items: center;
}

header.dashboard-header>div .user {
    width: 58px;
    height: 58px;
    border-radius: 50%;
    overflow: hidden;
}

header.dashboard-header>div .logo {
    width: 84px;
}

header.dashboard-header>div .search-theme {
    width: 100%;
    margin: 0 15px;
    display: flex;
    align-items: center;
}

header.dashboard-header>div .search-theme .search-input {
    padding: 0 10px;
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid #00000033;
    border-radius: 8px;
    overflow: hidden;
    margin-left: 10px;
}

header.dashboard-header>div .search-theme .search-input input,
header.dashboard-header>div .search-theme .search-input button {
    all: unset;
}

header.dashboard-header>div .search-theme .search-input input {
    width: 100%;
}

header.dashboard-header>div .search-theme .search-input button {
    padding: 8px;
    text-align: center;
    cursor: pointer;
}

header.dashboard-header>div .search-theme .search-input button.search-btn {
    border-right: 1px solid #00000033;
}

header.dashboard-header>div .search-theme .dark-theme {
    all: unset;
    padding: 8px 15px;
    text-align: center;
    border-radius: 8px;
    background-color: #D9D9D9;
    color: #000000;
    cursor: pointer;
    font-size: 18px;
}

header.dashboard-header .mobile-menu {
    all: unset;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    font-size: 22px;
}

@media(max-width:767px) {
    header.dashboard-header .container-fluid {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.dashboard-mobile-menu-container {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background-color: #fff;
    z-index: 50;
    transform: translateX(100%);
    transition: all 0.6s;
}

.dashboard-mobile-menu-container.open {
    transform: translateX(0);
}

.dashboard-mobile-menu-container .menu-header {
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
}

.dashboard-mobile-menu-container .menu-header .close-menu {
    all: unset;
    padding: 10px;
    text-align: center;
    font-size: 22px;
    cursor: pointer;
}

.dashboard-mobile-menu-container .menu-header .dark-theme {
    all: unset;
    padding: 8px 15px;
    text-align: center;
    border-radius: 8px;
    background-color: #D9D9D9;
    color: #000000;
    cursor: pointer;
    font-size: 18px;
}

.dashboard-mobile-menu-container .menu-links {
    height: 90%;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.dashboard-mobile-menu-container .menu-links li {
    padding: 15px 0;
    margin-bottom: 10px;
    width: 100%;
    border-radius: 8px;
}

.dashboard-mobile-menu-container .menu-links li a {
    display: flex;
    padding: 0 10px;
}

.dashboard-mobile-menu-container .menu-links li a i {
    margin-left: 15px;
}

/* end header */
/* start sidebar */
aside.dashboard-sidebar {
    padding: 8px;
    max-width: 75px;
    /* max-width: 258px; */
    height: fit-content;
    border-radius: 8px;
    background-color: #FFFFFFB2;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

aside.dashboard-sidebar ul.lenks {
    width: 100%;
    padding: 0;
    margin: 0;
}

aside.dashboard-sidebar ul.links li {
    width: 100%;
    cursor: pointer;
    text-align: center;
    padding: 10px 5px;
    border-radius: 8px;
    margin-bottom: 10px;
}

aside.dashboard-sidebar ul.links li.active,
.dashboard-mobile-menu-container .menu-links li.active {
    background-image: linear-gradient(#0278FF, #00C2FF);
    color: #fff;
}

aside.dashboard-sidebar ul.links li a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

aside.dashboard-sidebar ul.links li a p {
    width: 0;
    overflow: hidden;
    text-wrap: nowrap;
    /* transition: all 0.5s; */
}

aside.dashboard-sidebar:hover:hover {
    max-width: 258px;
}

aside.dashboard-sidebar:hover ul.links li a {
    justify-content: start;
}

aside.dashboard-sidebar:hover ul.links li a p {
    width: unset;
    margin-right: 10px !important;
}

/* end sidebar */
/* start home section */
.dashboard-home-section .welcome-box {
    padding-bottom: 30px;
    border-bottom: 1px solid #00000033;
}

.dashboard-home-section .welcome-box h3.title {
    padding: 10px 0 50px 0;
    border-bottom: 1px solid #00000033;
}

.dashboard-home-section .welcome-box .new-subscriptions {
    margin-top: 25px;
    padding: 25px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-image: linear-gradient(#0278FF, #00C2FF);
    border-radius: 25px;
    color: #fff !important;
    position: relative;
}

.dashboard-home-section .welcome-box .new-subscriptions h5 {
    font-size: 32px;
}

.dashboard-home-section .welcome-box .new-subscriptions .image {
    position: absolute;
    bottom: 0;
    left: 60%;
    transform: translateX(-60%);
    height: 200px;
}

.dashboard-home-section .welcome-box .new-subscriptions .avatars-container {
    display: flex;
    align-items: center;
}

.dashboard-home-section .welcome-box .new-subscriptions .avatars-container p {
    font-size: 47px;
    margin-left: 10px;
}

.dashboard-home-section .welcome-box .new-subscriptions .avatars-container .avatars li {
    display: inline-block;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
}

.dashboard-home-section .welcome-box .new-subscriptions .avatars-container .avatars li img {
    width: 100%;
    height: 100%;
}

.dashboard-home-section .welcome-box .new-subscriptions .avatars-container .avatars {
    display: flex;
    flex-direction: row-reverse;
}

.dashboard-home-section .welcome-box .new-subscriptions .avatars-container .avatars li:not(:first-child) {
    margin-left: -50px;
}

@media(max-width:767px) {
    .dashboard-home-section .welcome-box .new-subscriptions {
        flex-direction: column;
    }

    .dashboard-home-section .welcome-box .new-subscriptions h5 {
        margin-bottom: 15px;
    }

    .dashboard-home-section .welcome-box .new-subscriptions .image {
        display: none;
    }

    .dashboard-home-section .welcome-box .new-subscriptions .avatars-container p {
        font-size: 25px;
    }

    .dashboard-home-section .welcome-box .new-subscriptions .avatars-container .avatars li {
        width: 70px;
        height: 70px;
    }
}

/* start analysis section */
.analysis {
    margin-top: 15px;
    display: flex;
}

.analysis .right.students {
    width: 60%;
}

.analysis .title {
    font-size: 12px;
    color: #1E1E1EB2;
}

.analysis .right.students .content {
    background-color: #fff;
    border-radius: 25px;
    padding: 10px;
    margin-bottom: 15px;
    max-height: 439px;
    overflow-y: auto;
    direction: ltr;
}

.scrolled-y::-webkit-scrollbar {
    background-color: #EEEEEE;
    width: 8px;
}

.scrolled-y::-webkit-scrollbar-thumb {
    background-color: #CFCFCF;
}

.analysis .right.students .content .student-row {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border-radius: 8px;
    background-color: #FBFBFB;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.analysis .right.students .content .student-row .image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
}

.analysis .right.students .content .student-row p {
    margin: 0;
    padding: 0;
    font-size: 12px;
}

.analysis .right.students .content .student-row>* {
    width: calc(95% / 6) !important;
    text-align: center;
    overflow: hidden;
}

@media(max-width:767px) {
    .analysis {
        flex-direction: column;
        flex-wrap: wrap;
    }

    .analysis .right.students {
        width: 100%;
    }

    .analysis .right.students .content {
        width: 100%;
        overflow-x: auto;
        padding: 0;
        border-radius: 0;
    }

    .analysis .right.students .content .student-row {
        width: fit-content;
    }

    .analysis .right.students .content .student-row * {
        text-wrap: nowrap;
        margin-left: 10px !important;
    }
}

.analysis .left {
    padding: 0 20px;
}

.analysis .left .top {
    padding: 0 0 20px 0;
    border-bottom: 1px solid #00000033;
}

.analysis .left .bottom {
    padding: 10px 0;
}

.analysis .left .bottom>div {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
    background-color: #F1CB34;
    border-radius: 25px;
    padding: 10px;
    margin-top: 15px;
}

.analysis .left .bottom>div ul {
    display: flex;
}

.analysis .left .bottom>div ul li {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media(max-width:767px) {
    .analysis .title {
        padding: 10px 0;
    }

    .analysis .left {
        width: 100%;
        padding: 0;
    }

    .analysis .left .bottom {
        padding: 0;
    }

    .analysis .left .bottom>div .image {
        display: none;
    }

    .analysis .left .bottom>div ul {
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .analysis .left .bottom>div ul li p {
        font-size: 18px;
    }
}

/* end analysis section */
/* end home section */
/* start students section */
.dashboard-students-section .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    border-bottom: 1px solid #00000033;
    margin-bottom: 15px;
}

.dashboard-students-section .head h6 {
    color: #1E1E1E;
    font-size: 32px;
}

.dashboard-students-section .head .add-new-student {
    all: unset;
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.dashboard-students-section .head .add-new-student i {
    color: #00BD99;
    font-size: 25px;
    margin-right: 10px;
}

.student-table,
.student-table thead,
.student-table thead tr,
.student-table tbody,
.student-table tbody tr {
    width: 100%;
}

.student-table thead {
    display: block;
    padding: 15px !important;
    border-radius: 25px;
    background-color: #FFFFFF99 !important;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.student-table thead tr,
.student-table tbody tr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}

.student-table thead tr th {
    color: #1E1E1E;
    font-size: 12px;
    width: calc(95% / 9);
}

.exam-results thead tr th {
    width: calc(95% / 6) !important;
}

.student-table thead tr th:first-child {
    width: 40px;
}

.student-table tbody {
    display: block;
    padding: 15px;
    background-color: #FFFFFF99;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 25px;
    margin-top: 15px;
    /* height: 439px; */
    /* overflow-y: auto; */
    direction: ltr;
}

.student-table tbody tr {
    background-color: #FBFBFB;
    padding: 8px;
    margin-bottom: 10px;
    border-radius: 25px;
    cursor: pointer;
}

.student-table tbody tr td.image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
}

.student-table tbody tr td {
    font-size: 12px;
    width: calc(95% / 9);
    text-overflow: clip;
    overflow: hidden;
}

.exam-results tbody tr td {
    width: calc(95% / 6);
    padding: 15px 0;
}
.exam-results tbody tr td button {
    all: unset;
    padding: 5px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 14px;
    cursor: pointer;
}
@media(max-width:767px) {
    .dashboard-students-section {
        overflow: hidden;
    }

    .student-table {
        width: 100%;
        display: block;
        overflow-x: auto;
    }

    .student-table thead,
    .student-table thead tr,
    .student-table tbody,
    .student-table tbody tr {
        width: fit-content;
    }

    .student-table thead tr *,
    .student-table tbody tr * {
        text-wrap: nowrap;
        margin-left: 10px;
    }
}

/* end students section */
/* start student details section */
.student-details-section h1 {
    font-size: 32px;
    color: #1E1E1E;
    padding: 15px 0 25px 0;
    border-bottom: 1px solid #00000033;
}
.student-details-section .student-info .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 15px;
}
.student-details-section .student-info .section-header h6 {
    padding: 15px 0;
}
.student-details-section .student-info .section-header button {
    all: unset;
    padding: 15px 10px;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer;
    border-radius: 14px;
}
.student-details-section .student-table tbody {
    height: fit-content !important;
}

.student-details-section .student-info {
    padding: 0 0 30px 0;
    border-bottom: 1px solid #00000033;
}

.student-details-section .student-indicator {
    padding: 15px 0 30px 0;
    border-bottom: 1px solid #00000033;
}

.student-details-section .student-indicator h6 {
    padding: 15px 0;
}

.student-details-section .student-indicator .chart-indicator {
    height: 443px !important;
    width: 100%;
    display: flex;
    justify-content: center;
}

.student-details-section .student-indicator .chart-indicator canvas {
    height: 100% !important;
}

@media(max-width:767px) {
    .student-details-section h1 {
        font-size: 25px;
    }
}

.student-details-section .courses-homework {
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 15px 0;
    border-bottom: 1px solid #00000033;
}

.student-details-section .courses-homework .courses .content {
    direction: ltr;
    /* max-height: 543px; */
    /* overflow-y: auto; */
    background-color: #FFFFFF99;
    border-radius: 25px;
    padding: 15px 10px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
}

.student-details-section .courses-homework .courses .content .course {
    background-color: #FFFFFF;
    border-radius: 25px;
    display: flex;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    width: calc(95% / 3);
}

.student-details-section .courses-homework .courses .content .course .image {
    /* width: 30%; */
    height: 150px;
    margin-left: 15px;
}

@media(max-width:767px) {
    .student-details-section .courses-homework .courses .content .course {
        width: 100%;
    }
}

.student-details-section .courses-homework .courses .content .course div h6 {
    font-size: 14px;
}

.student-details-section .courses-homework .courses .content .course div p {
    font-size: 12px;
    color: #00000080;
}

.student-details-section .courses-homework .home-work-marks .content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 15px 10px;
    direction: ltr;
    max-height: 543px;
    overflow-y: auto;
}

.student-details-section .courses-homework .home-work-marks .content .box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 45%;
    padding: 10px;
    background-color: #FBFBFB;
    border-radius: 25px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    font-size: 14px;
    margin-bottom: 10px;
}

@media(max-width:767px) {
    .student-details-section .courses-homework .home-work-marks .content .box {
        flex-direction: column;
    }

    .student-details-section .courses-homework .home-work-marks .content .box p {
        text-align: center;
    }
}

.student-details-section .student-homeworks h6 {
    padding: 15px 0;
}

.student-details-section .student-homeworks table,
.student-details-section .student-homeworks table thead,
.student-details-section .student-homeworks table tbody,
.student-details-section .student-homeworks table tr {
    width: 100%;
    display: block;
}

.student-details-section .student-homeworks table {
    padding: 15px !important;
    background-color: #FFFFFF99;
    border-radius: 25px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.student-details-section .student-homeworks table tr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}

.student-details-section .student-homeworks table thead {
    padding: 10px !important;
    background-image: linear-gradient(#0278FF, #00C2FF);
    color: #fff;
    border-radius: 15px;
    margin-bottom: 15px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.student-details-section .student-homeworks table thead tr th,
.student-details-section .student-homeworks table tbody tr td {
    width: calc(100% / 7);
}

.student-details-section .student-homeworks table tbody tr {
    background-color: #FBFBFB;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 15px;
    padding: 10px;
    font-size: 14px;
    margin-bottom: 15px;
}

.student-details-section .student-homeworks table tbody tr td.text-danger {
    color: #FF0101 !important;
    font-weight: bold !important;
}

@media(max-width:767px) {
    .student-details-section .student-homeworks table {
        overflow-x: auto;
    }

    .student-details-section .student-homeworks table thead,
    .student-details-section .student-homeworks table tbody,
    .student-details-section .student-homeworks table tr {
        width: fit-content;
    }

    .student-details-section .student-homeworks table tr * {
        text-wrap: nowrap;
        margin-left: 10px;
    }
}

/* start student edit */
.dashboard-student-edit {
    transition: all 0.5s;
}

.dashboard-student-edit.close {
    height: 0;
    overflow: hidden;
}

.dashboard-student-edit form {
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.dashboard-student-edit form input,
.dashboard-student-edit form select {
    all: unset;
    padding: 10px 5px;
    width: calc(95% / 4);
    margin-bottom: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 14px;
}

.dashboard-student-edit form button {
    all: unset;
    padding: 15px 10px;
    text-align: center;
    background-color: #00C2FF;
    color: #fff;
    cursor: pointer;
    width: 50%;
    margin: 15px auto;
    border-radius: 14px;
}

/* end student details section */
/* start categories section */
.dashboard-categories-section h1 {
    font-size: 32px;
    color: #1E1E1E;
    padding: 15px 10px 30px;
    margin-bottom: 15px;
    border-bottom: 1px solid #00000033;
}

@media(max-width:767px) {
    .dashboard-categories-section {
        width: 100%;
    }
}

.dashboard-categories-section .categories-create-new {
    padding: 15px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.dashboard-categories-section .categories-create-new .category {
    width: 22%;
    border-radius: 25px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    overflow: hidden;
    margin-bottom: 15px;
    margin-left: 3%;
}

.dashboard-categories-section .categories-create-new .category .top {
    background-color: #fff;
    position: relative;
    padding: 15px;

}
.dashboard-categories-section .categories-create-new .category .top > div.update-category {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    width: 100%;
}
.dashboard-categories-section .categories-create-new .category .top > div.update-category input {
    padding: 10px 5px;
    margin-bottom: 10px;
    border: 1px solid #414141;
    border-radius: 8px;
    width: 100%;
    display: block;
}
.dashboard-categories-section .categories-create-new .category .top > div.update-category button {
    all: unset;
    padding: 10px 0;
    text-align: center;
    background-color: #F1CB34;
    border-radius: 8px;
    width: 100%;
    display: block;
    cursor: pointer;
}
.dashboard-categories-section .categories-create-new .category .top .icon {
    position: absolute;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: #EFEFEF;
    color: #000000CC;
    top: 5px;
    left: 5px;
    cursor: pointer;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard-categories-section .categories-create-new .category .top h6 {
    color: #1E1E1E;
    font-size: 20px;
}

.dashboard-categories-section .categories-create-new .category .top div {
    display: flex;
    align-items: center;
    padding: 10px 0;
}

.dashboard-categories-section .categories-create-new .category .top div p {
    margin: 0;
    padding: 0 15px 0 0;
}

.dashboard-categories-section .categories-create-new .category .top div p:first-child {
    border-left: 1px solid #0000004D;
    padding: 0 0 0 15px;
}

.dashboard-categories-section .categories-create-new .category .bottom {
    background-color: #FBFBFB;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dashboard-categories-section .categories-create-new .category .bottom .current-subscriptions p {
    margin: 0;
    padding: 0;
    text-align: center;
}

.dashboard-categories-section .categories-create-new .category .bottom .current-subscriptions p:first-child {
    font-size: 20px;
    font-weight: 700px;
    color: #1E1E1E;
}

.dashboard-categories-section .categories-create-new .category .bottom .current-subscriptions p:nth-child(2) {
    font-size: 12px;
    color: #1E1E1E80;
}

.dashboard-categories-section .categories-create-new .category .bottom .delete-icon i {
    color: #FF3838 !important;
    font-size: 20px;
    cursor: pointer;
}

.dashboard-categories-section .categories-create-new .new-category {
    width: 22%;
    border-radius: 25px;
    border: 1px solid #fff;
    background-color: #FFFFFF38;
    margin-bottom: 15px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    min-height: 184px;
}

@media(max-width:767px) {

    .dashboard-categories-section .categories-create-new .category,
    .dashboard-categories-section .categories-create-new .new-category {
        width: 100%;
    }
}

.dashboard-categories-section .categories-create-new .new-category i {
    font-size: 25px;
    color: #1E1E1E;
    margin-bottom: 10px;
}

.dashboard-categories-section .categories-create-new .new-category p {
    color: #1E1E1E;
    font-size: 13px;
}

.categories-filter-menu {
    width: 280px;
    background-color: #FFFFFF80;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 20px 10px;
}

@media(max-width:767px) {
    .categories-filter-menu {
        width: 100%;
        display: none;
    }
}

.categories-filter-menu .category-type {
    padding: 0 0 25px 0;
    border-bottom: 1px solid #00000033;
}

.categories-filter-menu .category-type h6,
.categories-filter-menu .school-year h6 {
    padding: 15px 0;
}

.categories-filter-menu .category-type div,
.categories-filter-menu .school-year div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.categories-filter-menu .category-type div button,
.categories-filter-menu .school-year div button {
    all: unset;
    width: 45%;
    text-align: center;
    border-radius: 8px;
    border: 1px solid #00000080;
    cursor: pointer;
    padding: 5px 0;
    font-size: 12px;
    margin-bottom: 10px;
}

.create-new-place-popup,
.update-course-popup,
.update-unite-popup,
.update-lesson-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0000000c;
    z-index: 999999;
    justify-content: center;
    align-items: center;
    display: none;
}

.create-new-place-popup.open,
.update-course-popup.open,
.update-unite-popup.open,
.update-lesson-popup.open {
    display: flex;
}

.create-new-place-popup .add-new-place-box,
.update-unite-popup .box,
.update-lesson-popup .box {
    background-color: #fff;
    border-radius: 8px;
    width: 40%;
    padding: 15px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.create-new-place-popup .add-new-place-box input,
.update-unite-popup .box input,
.update-lesson-popup .box input {
    all: unset;
    padding: 10px 5px;
    margin-bottom: 15px;
    width: 100%;
    border-radius: 5px;
    background-color: #EEEEEE;
    border: 1px solid #ddd;
}

.create-new-place-popup .add-new-place-box input::placeholder {
    opacity: 0.5;
}

.create-new-place-popup .add-new-place-box input:focus::placeholder {
    color: transparent;
}

.create-new-place-popup .add-new-place-box button,
.update-unite-popup .box button,
.update-lesson-popup .box button {
    all: unset;
    padding: 10px 0;
    text-align: center;
    width: 100%;
    background-color: #0278FF;
    cursor: pointer;
    color: #fff;
    border-radius: 8px;
    transition: background-color 0.5s;
}

.create-new-place-popup .add-new-place-box button:hover {
    background-color: #0278ffb4;
}

/* end categories section */
/* start dashboard courses page */
.dashboard-courses {
    /* margin: 15px 0; */
}

.dashboard-courses h1 {
    font-size: 32px;
    color: #1E1E1E;
    padding: 15px 10px 30px;
    margin-bottom: 15px;
    border-bottom: 1px solid #00000033;
}

.dashboard-courses .add-new-course {
    border-radius: 25px;
    background-color: #FFFFFF99;
    border: 1px solid #FFFFFF;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    display: flex;
    padding: 15px 15px 25px 15px;
}

.dashboard-courses .add-new-course .course-image {
    width: 20%;
    position: relative;
    border: 1px dotted #00000033;
    padding: 5px;
    margin-left: 15px;
    cursor: pointer;
}

.dashboard-courses .add-new-course .course-image p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    text-align: center;
    color: #0278FF;
    border: 1px dotted #0278FF;
    border-radius: 8px;
    background-color: #fff;
}

.dashboard-courses .add-new-course .course-image img {
    /* display: none; */
}

.dashboard-courses .add-new-course .course-title-desc {
    display: flex;
    flex-direction: column;
    width: 30%;
    padding: 0 0 0 15px;
    border-left: 1px solid #0000001A;
}

.dashboard-courses .add-new-course .course-title-desc input,
.dashboard-courses .add-new-course .course-title-desc textarea {
    all: unset;
    padding: 10px;
    border: 1px solid #0000001A;
    border-radius: 8px;
}

.dashboard-courses .add-new-course .course-title-desc input::placeholder,
.dashboard-courses .add-new-course .course-title-desc textarea::placeholder {
    color: #00000066;
    font-size: 12px;
}

.dashboard-courses .add-new-course .course-title-desc input:focus::placeholder,
.dashboard-courses .add-new-course .course-title-desc textarea:focus::placeholder {
    color: transparent !important;
}

.dashboard-courses .add-new-course .course-title-desc input {
    margin-bottom: 15px;
}

.dashboard-courses .add-new-course .course-title-desc textarea {
    height: 119px;
}

.dashboard-courses .add-new-course .school-year,
.dashboard-courses .add-new-course .category-type {
    padding: 0 15px 0 20px;
    border-left: 1px solid #0000001A;
    width: 15%;
}

.dashboard-courses .add-new-course .school-year h6,
.dashboard-courses .add-new-course .category-type h6,
.dashboard-courses .add-new-course .publish-date h6 {
    font-size: 12px;
    color: #1E1E1E80;
    margin-bottom: 15px;
}

.dashboard-courses .add-new-course .school-year ul,
.dashboard-courses .add-new-course .category-type ul {
    display: flex;
    flex-direction: column;
}

.dashboard-courses .add-new-course .school-year ul li,
.dashboard-courses .add-new-course .category-type ul li {
    color: #1E1E1ECC;
    font-size: 12px;
    padding: 10px 25px;
    text-align: center;
    border: 1px solid #00000033;
    border-radius: 8px;
    margin-bottom: 15px;
    cursor: pointer;
}

.dashboard-courses .add-new-course .school-year ul li.active,
.dashboard-courses .add-new-course .category-type ul li.active {
    background-color: #0278FF;
    color: #fff;
}

.dashboard-courses .add-new-course .publish-date {
    padding: 0 15px 0 0;
    width: 20%;
    display: flex;
    flex-direction: column;
}

.dashboard-courses .add-new-course .publish-date input {
    all: unset;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 8px;
    padding: 10px 0;
    border: 1px solid #0000001A;
}

.dashboard-courses .add-new-course .publish-date input.course-price {
    background-color: #EFEFEF;
    text-align: center;
    border: none;
}

.dashboard-courses .add-new-course .publish-date input.course-price::placeholder {
    color: #1E1E1E66;
}

.dashboard-courses .add-new-course .publish-date input.course-price:focus::placeholder {
    color: transparent !important;
}

.dashboard-courses .add-new-course .publish-date button {
    all: unset;
    padding: 15px 0;
    text-align: center;
    border-radius: 8px;
    background-color: #00BD99;
    color: #fff;
    font-size: 15px;
    cursor: pointer;
}

.dashboard-courses .courses-rows {
    padding: 15px 0;
    margin-top: 25px;
    border-top: 1px solid #00000033;
}

.dashboard-courses .courses-rows .dashboard-course-box {
    padding: 20px 10px;
    display: flex;
    align-items: center;
    border-radius: 25px;
    margin-bottom: 15px;
    background-color: #FFFFFF99;
    border: 1px solid #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.dashboard-courses .courses-rows .dashboard-course-box .image {
    width: 10%;
    height: 60px;
    border-radius: 8px;
    overflow: hidden;
}

.dashboard-courses .courses-rows .dashboard-course-box .title-desc {
    width: 10%;
    padding: 0 15px;
    border-left: 1px solid #00000033;
}

.dashboard-courses .courses-rows .dashboard-course-box .title-desc h6 {
    color: #000000;
    font-size: 14px;
    font-weight: 700;
}

.dashboard-courses .courses-rows .dashboard-course-box .title-desc p {
    color: #00000080;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
}

@media(max-width:767px) {
    .dashboard-courses .add-new-course {
        flex-direction: column;
    }

    .dashboard-courses .add-new-course>* {
        width: 100% !important;

    }

    .dashboard-courses .add-new-course>*:not(.course-image) {
        border-left: none !important;
    }

    .dashboard-courses .courses-rows {
        width: 100%;
        overflow-x: auto;
    }

    .dashboard-courses .courses-rows .dashboard-course-box {
        width: fit-content;
    }

    .dashboard-courses .add-new-course .course-image {
        height: 200px;
        margin-bottom: 10px;
    }

    .dashboard-courses .courses-rows .dashboard-course-box>* {
        text-wrap: nowrap;
    }

    .dashboard-courses .courses-rows .dashboard-course-box .title-desc {
        width: 40%;
    }

    .dashboard-courses .courses-rows .dashboard-course-box .title-desc p {
        text-wrap: wrap;
    }
}

.dashboard-courses .courses-rows .dashboard-course-box .category-school-level,
.dashboard-courses .courses-rows .dashboard-course-box .units-count,
.dashboard-courses .courses-rows .dashboard-course-box .lessons,
.dashboard-courses .courses-rows .dashboard-course-box .videos-count,
.dashboard-courses .courses-rows .dashboard-course-box .course-price,
.dashboard-courses .courses-rows .dashboard-course-box .subscriptions {
    padding: 0 10px;
    border-left: 1px solid #00000033;
}

.dashboard-courses .courses-rows .dashboard-course-box .category-school-level p {
    margin: 0;
    padding: 5px 0;
    font-size: 14px;
    font-weight: 500;
}

.dashboard-courses .courses-rows .dashboard-course-box .units-count,
.dashboard-courses .courses-rows .dashboard-course-box .lessons,
.dashboard-courses .courses-rows .dashboard-course-box .videos-count,
.dashboard-courses .courses-rows .dashboard-course-box .course-price,
.dashboard-courses .courses-rows .dashboard-course-box .subscriptions {
    height: 100%;
    display: flex;
    align-items: center;
}

.dashboard-courses .courses-rows .dashboard-course-box .units-count p,
.dashboard-courses .courses-rows .dashboard-course-box .lessons p,
.dashboard-courses .courses-rows .dashboard-course-box .videos-count p,
.dashboard-courses .courses-rows .dashboard-course-box .course-price p,
.dashboard-courses .courses-rows .dashboard-course-box .subscriptions p {
    margin: 0;
    padding: 0 10px;
    font-size: 14px;
}

.dashboard-courses .courses-rows .dashboard-course-box .course-date {
    padding: 0 10px 0 20px;
}

.dashboard-courses .courses-rows .dashboard-course-box .course-date p {
    padding: 0;
    margin: 0;
}

.dashboard-courses .courses-rows .dashboard-course-box .course-date p:first-child {
    font-size: 10px;
    color: #00000080;
}

.dashboard-courses .courses-rows .dashboard-course-box .course-date p:nth-child(2) {
    font-size: 12px;
    font-weight: 600;
    color: #000;
}

.dashboard-courses .courses-rows .dashboard-course-box .setting-delete-btn {
    display: flex;
    margin-right: 15px;
}

.dashboard-courses .courses-rows .dashboard-course-box .setting-delete-btn button {
    all: unset;
    padding: 10px;
    cursor: pointer;
    font-size: 15px;
}

.dashboard-courses .courses-rows .dashboard-course-box .setting-delete-btn button:first-child {
    margin-left: 10px;
}

.dashboard-courses .courses-rows .dashboard-course-box .setting-delete-btn button:nth-child(2) i {
    color: #FF3838;
}

.dashboard-courses .courses-rows .dashboard-course-box .add-units و .unit-course-box {
    padding: 0 10px;
}

.dashboard-courses .courses-rows .dashboard-course-box .add-units a,
.unit-course-box a {
    text-wrap: nowrap;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #0278FF;
    color: #fff;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
}

.update-course-popup .box {
    width: 70%;
    background-color: #fff;
    border-radius: 8px;
    padding: 15px 10px;
}

.update-course-popup .box .image {
    width: 100%;
    height: 200px;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
}

.update-course-popup .box .image label {
    all: unset;
    padding: 5px 15px;
    text-align: center;
    cursor: pointer;
    background-color: #0278FF;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    border-radius: 5px;
}

.update-course-popup .box .name-price,
.update-course-popup .box .year-place {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.update-course-popup .box .name-price input {
    all: unset;
    width: calc(95% / 2);
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 10px 5px;
    background-color: #eee;
}

.update-course-popup .box .year-place select {
    width: calc(95% / 2);
}

.update-course-popup .box textarea {
    all: unset;
    width: 100%;
    height: 100px;
    border: 1px solid #ddd;
    background-color: #eee;
    border-radius: 5px;
    padding: 10px 0;
}

.update-course-popup .box button {
    all: unset;
    cursor: pointer;
    padding: 10px 0;
    text-align: center;
    color: #fff;
    background-color: #f1c40f;
    border-radius: 5px;
    width: 100%;
    margin-top: 15px;
}

/* end dashboard courses page */
/* start dashboard units page */
.dashboard-units-page h1,
.dashboard-lessons-page h1,
.dashboard-videos-page h1,
.dashboard-exams-page h1 {
    font-size: 32px;
    color: #1E1E1E;
    padding: 15px 10px 30px;
    margin-bottom: 15px;
    border-bottom: 1px solid #00000033;
}

.dashboard-units-page .new-unit,
.dashboard-lessons-page .new-lesson,
.dashboard-queastions-answers .add-new-question,
.dashboard-videos-page .new-video .bottom {
    padding: 15px;
    border-radius: 25px;
    background-color: #FFFFFF99;
    border: 1px solid #ffff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    display: flex;
}

.dashboard-units-page .new-unit .unit-image,
.dashboard-lessons-page .new-lesson .lesson-image,
.dashboard-videos-page .new-video .bottom .video-image {
    width: 20%;
    position: relative;
    border: 1px dashed #0000;
    margin-left: 15px;
}

@media(max-width:767px) {

    .dashboard-units-page .new-unit,
    .dashboard-lessons-page .new-lesson,
    .dashboard-queastions-answers .add-new-question,
    .dashboard-videos-page .new-video .bottom {
        flex-direction: column;
    }

    .dashboard-units-page .new-unit *:not(.unit-image p),
    .dashboard-lessons-page .new-lesson *:not(.lesson-image p),
    .dashboard-videos-page .new-video .bottom *:not(.video-image p) {
        width: 100% !important;
    }

    .dashboard-units-page .new-unit .unit-image,
    .dashboard-lessons-page .new-lesson .lesson-image,
    .dashboard-videos-page .new-video .bottom .video-image {
        height: 200px;
        margin-bottom: 10px;
    }
}

.dashboard-units-page .new-unit .unit-image p,
.dashboard-lessons-page .new-lesson .lesson-image p,
.dashboard-videos-page .new-video .bottom .video-image p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    padding: 10px;
    text-align: center;
    color: #0278FF;
    border: 1px dotted #0278FF;
    border-radius: 8px;
}

.dashboard-units-page .new-unit .unit-name,
.dashboard-lessons-page .new-lesson .lesson-name,
.dashboard-videos-page .new-video .bottom .video-name {
    width: 40%;
    display: flex;
    flex-wrap: wrap;
}

.dashboard-units-page .new-unit .unit-name .unit-name-input,
.dashboard-lessons-page .new-lesson .lesson-name .lesson-name-input,
.dashboard-videos-page .new-video .bottom .video-name .video-name-input {
    all: unset;
    width: 100%;
    padding: 8px;
    margin-bottom: 15px;
    border-radius: 8px;
    border: 1px solid #0000001A;
}

.dashboard-units-page .new-unit .unit-name .unit-name-input::placeholder,
.dashboard-lessons-page .new-lesson .lesson-name .lesson-name-input::placeholder,
.dashboard-videos-page .new-video .bottom .video-name .video-name-input::placeholder {
    color: #00000066;
    font-size: 12px;
}

.dashboard-units-page .new-unit .unit-name .unit-name-input:focus::placeholder,
.dashboard-lessons-page .new-lesson .lesson-name .lesson-name-input:focus::placeholder,
.dashboard-videos-page .new-video .bottom .video-name .video-name-input:focus::placeholder {
    color: transparent !important;
}

.dashboard-units-page .new-unit .unit-name select,
.dashboard-units-page .new-unit .unit-name input.unit-created-date,
.dashboard-lessons-page .new-lesson .lesson-name select,
.dashboard-lessons-page .new-lesson .lesson-name input.lesson-created-date,
.dashboard-videos-page .new-video .bottom .video-name select,
.dashboard-videos-page .new-video .bottom .video-name input.video-created-date {
    width: 49%;
}

.dashboard-units-page .new-unit .unit-name select {
    width: 100%;
}

.dashboard-units-page .new-unit .unit-name select,
.dashboard-lessons-page .new-lesson .lesson-name select,
.dashboard-videos-page .new-video .bottom .video-name select {
    margin-left: 2%;
    background-color: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #0000001A;
}

.dashboard-units-page .new-unit .unit-name input.unit-created-date,
.dashboard-lessons-page .new-lesson .lesson-name input.lesson-created-date,
.dashboard-videos-page .new-video .bottom .video-name input.video-created-date {
    padding: 8px;
    margin-bottom: 15px;
    border-radius: 8px;
    border: 1px solid #0000001A;
}

.dashboard-units-page .new-unit .unit-name .save-data,
.dashboard-lessons-page .new-lesson .lesson-name .save-data,
.dashboard-videos-page .new-video .bottom .video-name .save-data {
    all: unset;
    width: 40%;
    padding: 10px;
    text-align: center;
    border-radius: 8px;
    background-color: #00BD99;
    color: #fff;
    cursor: pointer;
}

.dashboard-units-page .unit-course-box,
.dashboard-row-box {
    padding: 10px;
    border-radius: 25px;
    background-color: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

@media(max-width:767px) {

    .dashboard-units-page .unit-course-box,
    .dashboard-row-box {
        width: fit-content;
    }

    .dashboard-units-page .unit-course-box *,
    .dashboard-row-box * {
        text-wrap: nowrap;
    }
}

.dashboard-units-page .unit-course-box .image,
.dashboard-row-box .image {
    width: 15%;
    height: 73px;
    border-radius: 8px;
    overflow: hidden;
}

.dashboard-units-page .unit-course-box .title,
.dashboard-units-page .unit-course-box .lessons,
.dashboard-units-page .unit-course-box .videos,
.dashboard-units-page .unit-course-box .quiz,
.dashboard-units-page .unit-course-box .homework,
.dashboard-row-box>div,
.dashboard-row-box .title {
    padding: 0 10px;
}

.dashboard-units-page .unit-course-box .title,
.dashboard-row-box .title {
    margin: 0;
}

.dashboard-units-page .unit-course-box .lessons,
.dashboard-units-page .unit-course-box .videos,
.dashboard-units-page .unit-course-box .quiz,
.dashboard-units-page .unit-course-box .homework,
.dashboard-row-box>div {
    display: flex;
    align-items: center;
}

.dashboard-units-page .unit-course-box .lessons,
.dashboard-units-page .unit-course-box .videos,
.dashboard-units-page .unit-course-box .quiz,
.dashboard-units-page .unit-course-box .title,
.dashboard-row-box .separate {
    border-left: 1px solid #00000033;
}

.dashboard-units-page .unit-course-box .lessons p,
.dashboard-units-page .unit-course-box .videos p,
.dashboard-units-page .unit-course-box .quiz p,
.dashboard-units-page .unit-course-box .homework p,
.dashboard-row-box>div p {
    margin: 0;
    padding: 0 10px 0 15px;
    font-size: 14px;
}

.dashboard-units-page .unit-course-box .date-setting,
.dashboard-row-box .date-setting {
    margin: 0 auto 0 10px;
    display: flex;
    align-items: center;
}

.dashboard-units-page .unit-course-box .date-setting .date,
.dashboard-row-box .date-setting .date {
    padding: 0 15px;
}

.dashboard-units-page .unit-course-box .date-setting .date p:first-child,
.dashboard-row-box .date-setting .date p:first-child {
    font-size: 10px;
    color: #00000080;
}

.dashboard-units-page .unit-course-box .date-setting .date p:nth-child(2) {
    font-size: 12px;
}

.dashboard-units-page .unit-course-box .date-setting .controll-btns button,
.dashboard-row-box .date-setting .controll-btns button {
    all: unset;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    font-size: 20px;
}

.dashboard-row-box .date-setting .controll-btns a {
    padding: 5px 10px;
    text-align: center;
    border-radius: 8px;
    background-color: #0278FF;
    color: #fff;
    margin-left: 10px;
    font-size: 13px;
}

.dashboard-units-page .unit-course-box .date-setting .controll-btns button:nth-child(2) i,
.dashboard-row-box .date-setting .controll-btns button:nth-child(2) i {
    margin-right: 10px;
    color: #FF3838;
}

/* end dashboard units page */
/* start dashboard section container */
.dashboard-section-container {
    margin-top: 50px;
    padding: 40px 10px 15px 0;
    position: relative;
    border-top: 1px dotted #0000001A;
}

.dashboard-section-container h6 {
    position: absolute;
    width: 30%;
    top: -20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    border-radius: 25px;
    padding: 10px 30px;
    margin: 0;
    text-align: center;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    font-size: 14px;
}

@media(max-width:767px) {
    .dashboard-section-container .content {
        width: 100%;
        overflow-x: auto;
    }

    .dashboard-section-container h6 {
        width: fit-content;
        text-wrap: nowrap;
    }
}

/* end dashboard section container */
/* start dashboard lessons page */
.dashboard-lessons-page .new-lesson .lesson-name {
    width: 50%;
}

/* end dashboard lessons page */
/* start dashboard videos page */
.dashboard-videos-page .new-video .top {
    padding: 10px;
    border-radius: 25px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    cursor: pointer;
}

.dashboard-videos-page .new-video .top i {
    margin-left: 10px;
}

/* end dashboard videos page */
/* start dashboard exams page */
.dashboard-exams-page .new-exam {
    display: flex;
    justify-content: space-evenly;
    background-color: #FFFFFF99;
    border-radius: 25px;
    padding: 15px;
    margin-top: 30px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.dashboard-exams-page .new-exam .inputs {
    width: 60%;
}

.dashboard-exams-page .new-exam .inputs>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
}

@media(max-width:767px) {
    .dashboard-exams-page .new-exam {
        flex-direction: column;
    }

    .dashboard-exams-page .new-exam .inputs *,
    .dashboard-exams-page .new-exam .inputs,
    .dashboard-exams-page .new-exam .inputs>div * {
        width: 100% !important;
        margin-bottom: 10px !important;
    }

    .dashboard-exams-page .new-exam .inputs>div {
        flex-direction: column;
        border-left: none !important;
    }

    .dashboard-exams-page .new-exam .image {
        display: none;
    }
}

.dashboard-exams-page .new-exam .inputs>div input {
    all: unset;
}

.dashboard-exams-page .new-exam .inputs>div select,
.dashboard-exams-page .new-exam .inputs>div input {
    width: 45%;
    padding: 10px;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #0000001A;
    margin: 0;
    font-size: 12px;
}

.dashboard-exams-page .new-exam .inputs>div input::placeholder {
    color: #00000066;
}

.dashboard-exams-page .new-exam .inputs>div input:focus::placeholder {
    color: transparent;
}

.dashboard-exams-page .new-exam .inputs>div select {
    margin-left: 2%;
}

.dashboard-exams-page .new-exam .inputs .bottom textarea {
    all: unset;
    width: 100%;
    background-color: #fff;
    border: 1px solid #eee;
    font-size: 12px;
    padding: 5px;
}

.dashboard-exams-page .new-exam .inputs .bottom>div {
    width: 35%;
    display: flex;
    align-items: center;
    padding: 0 0 0 10px;
    border-left: 1px solid #00000033;
}

@media(max-width:767px) {
    .dashboard-exams-page .new-exam .inputs .bottom>div {
        border-left: none;
    }
}

.dashboard-exams-page .new-exam .inputs .bottom>div label {
    padding: 0 0 0 15px;
    font-size: 12px;
    color: #000000;
    text-wrap: nowrap;
}

.dashboard-exams-page .new-exam .inputs .bottom>div input {
    width: 100%;
}

.dashboard-exams-page .new-exam .inputs .bottom .price {
    width: 20%;
    text-align: center;
}

.dashboard-exams-page .new-exam .inputs .save-data {
    all: unset;
    width: 100%;
    padding: 10px 0;
    margin-top: 20px;
    text-align: center;
    background-color: #00BD99;
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
}

.dashboard-exams-page .new-exam .image {
    width: 266px;
}

.dashboard-exams-page .test-type {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0;
    margin-top: 20px;
    border-top: 1px solid #0000001A;
    border-bottom: 1px solid #0000001A;
}

.dashboard-exams-page .test-type li {
    padding: 10px 0;
    text-align: center;
    width: 32%;
    background-color: #fff;
    border-radius: 25px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border: 1px solid #0000001A;
    cursor: pointer;
}

.dashboard-exams-page .test-type li.active {
    color: #fff;
    background-color: #0278FF !important;
    border-color: #0278FF !important;
}

.dashboard-exams-page .content-container {
    padding: 15px 0;
    display: flex;
    flex-wrap: wrap;
}

.dashboard-exams-page .content-container .exams-row-box {
    width: 100%;
    padding: 15px;
    margin-bottom: 15px;
    background-color: #fff;
    border-radius: 25px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.dashboard-exams-page .content-container .exams-row-box .row-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
    border-bottom: 1px solid #0000001A;
    margin-bottom: 10px;
}

.dashboard-exams-page .content-container .exams-row-box .row-header .right li {
    padding: 0 15px 0 20px;
    position: relative;
}

.dashboard-exams-page .content-container .exams-row-box .row-header .right li:not(:last-child)::after {
    content: '\f104';
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.dashboard-exams-page .content-container .exams-row-box .row-header .left {
    display: flex;
    align-items: center;
    padding: 0 15px;
}

.dashboard-exams-page .content-container .exams-row-box .row-header .left a {
    padding: 10px 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    border-radius: 25px;
    font-weight: 500;
}

.dashboard-exams-page .content-container .exams-row-box .row-header .left a i {
    margin-left: 10px;
}

.dashboard-exams-page .content-container .exams-row-box .row-header .left a.students-mark,
.dashboard-exams-page .content-container .exams-row-box .row-header .left a.students-mark i {
    background-color: #0278FF1A;
    color: #0278FF;
    font-size: 12px;
}

.dashboard-exams-page .content-container .exams-row-box .row-header .left a.add-questions {
    background-color: #F2F2F2;
    color: #000000;
    font-size: 12px;
}

.dashboard-exams-page .content-container .exams-row-box .row-header .left button {
    all: unset;
    cursor: pointer;
}

.dashboard-exams-page .content-container .exams-row-box .row-header .left button.settings {
    padding: 5px;
    border-radius: 25px;
    background-color: #F2F2F2;
    color: #000;
    margin: 0 15px;
}

.dashboard-exams-page .content-container .exams-row-box .row-header .left button.settings i {
    color: #000;
}

.dashboard-exams-page .content-container .exams-row-box .row-header .left button.delete i {
    color: #FF3838;
}

@media(max-width:767px) {
    .dashboard-exams-page .content-container {
        width: 100%;
        /* overflow: auto; */
    }

    .dashboard-exams-page .content-container .exams-row-box .row-header {
        flex-direction: column;
    }

    .dashboard-exams-page .content-container .exams-row-box .row-header .right {
        margin-bottom: 10px;
    }

    .dashboard-exams-page .content-container .exams-row-box .row-header .right li {
        font-size: 12px;
    }

    .dashboard-exams-page .content-container .exams-row-box .row-header .left {
        flex-wrap: wrap;
        justify-content: center;
        padding: 0;
    }

    .dashboard-exams-page .content-container .exams-row-box .row-header .left a {
        width: 49%;
        margin-left: 1%;
        margin-bottom: 10px;
        padding: 10px 0;
    }
}

.dashboard-exams-page .content-container .exams-row-box .box-body {
    display: flex;
    width: 100%;
}

.dashboard-exams-page .content-container .exams-row-box .box-body>div {
    display: flex;
    padding: 15px 0;
    flex-direction: column;
}

.dashboard-exams-page .content-container .exams-row-box .box-body .right {
    width: 50%;
}

.dashboard-exams-page .content-container .exams-row-box .box-body .right .name p,
.dashboard-exams-page .content-container .exams-row-box .box-body .right .image-container>p {
    padding: 10px 0;
    font-size: 10px;
    color: #00000080;
}

.dashboard-exams-page .content-container .exams-row-box .box-body .right .name>div {
    display: flex;
    align-items: center;
    width: 80%;
    padding: 15px 10px;
    border-bottom: 1px solid #00000080;
}

.dashboard-exams-page .content-container .exams-row-box .box-body .right .name>div input {
    all: unset;
    width: 100%;
}

.dashboard-exams-page .content-container .exams-row-box .box-body .right .name>div .icon i {
    padding: 5px;
    border-radius: 25px;
    background-color: #F5F9FF;
    color: #0278FF;
    cursor: pointer;
}

.dashboard-exams-page .content-container .exams-row-box .box-body .right .image-container {
    padding: 15px 0;
}

.dashboard-exams-page .content-container .exams-row-box .box-body .right .image-container .new-image {
    width: 86px;
    height: 96px;
    border-radius: 12px;
    background-color: #F8F9FB;
    color: #0278FF !important;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px dotted #0278FF;
}

.dashboard-exams-page .content-container .exams-row-box .box-body .middle,
.dashboard-exams-page .content-container .exams-row-box .box-body .left {
    width: 25%;
    border-right: 1px solid #0000001A;
    align-items: center;
}

.dashboard-exams-page .content-container .exams-row-box .box-body .middle>div {
    width: 50%;
}

.dashboard-exams-page .content-container .exams-row-box .box-body .middle>div p,
.dashboard-exams-page .content-container .exams-row-box .box-body .left>div p {
    padding: 15px 0;
    color: #00000080;
    font-size: 10px;
}

.dashboard-exams-page .content-container .exams-row-box .box-body .middle>div input {
    all: unset;
    width: 100%;
    border: 1px solid #0000001A;
    border-radius: 8px;
    padding: 10px;
    background-color: #F5F9FF;
}

.dashboard-exams-page .content-container .exams-row-box .box-body .left .enter-subscriptions-count {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 60%;
}

.dashboard-exams-page .content-container .exams-row-box .box-body .left .enter-subscriptions-count>div {
    width: 45%;
}

.dashboard-exams-page .content-container .exams-row-box .box-body .left .enter-subscriptions-count>div input,
.dashboard-exams-page .content-container .exams-row-box .box-body .left .price input {
    all: unset;
    width: 100%;
    background-color: #F5F9FF;
    padding: 10px 5px;
    border-radius: 8px;
}

.dashboard-exams-page .content-container .exams-row-box .box-body .left .price {
    width: 60%;
}

@media(max-width:767px) {
    .dashboard-exams-page .content-container .exams-row-box .box-body {
        flex-direction: column;
    }

    .dashboard-exams-page .content-container .exams-row-box .box-body>div {
        border-right: none !important;
    }

    .dashboard-exams-page .content-container .exams-row-box .box-body>div,
    .dashboard-exams-page .content-container .exams-row-box .box-body .right .name>div,
    .dashboard-exams-page .content-container .exams-row-box .box-body .right .image-container .new-image,
    .dashboard-exams-page .content-container .exams-row-box .box-body .middle>div,
    .dashboard-exams-page .content-container .exams-row-box .box-body .left>div {
        width: 100% !important;
    }

    .dashboard-exams-page .content-container .exams-row-box .box-body .middle>div input {
        padding: 10px 0;
    }
}

/* end dashboard exams page */
/* start dashboard questions and answers page */
.dashboard-queastions-answers h1 {
    color: #1E1E1E;
    font-size: 32px;
    font-weight: 600;
    line-height: 32.32px;
}
.dashboard-queastions-answers.update-question .section-header , 
.update-exam .section-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 10px;
}
.dashboard-queastions-answers.update-question .section-header button ,
.update-exam .section-header button {
    all: unset;
    padding: 15px 10px;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 14px;
    cursor: pointer;
}
.dashboard-queastions-answers .row-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dashboard-queastions-answers .row-header ul {
    padding: 15px 0;
    margin: 0;
    display: flex;
    align-items: center;
}

.dashboard-queastions-answers .row-header ul li {
    padding: 0 10px 0 25px;
    position: relative;
}

.dashboard-queastions-answers .row-header ul li:not(:last-child):after {
    content: '\f104';
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

.dashboard-queastions-answers .row-header button {
    all: unset;
    padding: 10px;
    text-align: center;
    cursor: pointer;
}

.dashboard-queastions-answers .exam-name {
    color: #1E1E1E;
    font-size: 20px;
    padding: 15px 0;
    margin: 15px 0;
    text-align: center;
    border-top: 1px solid #0000001A;
    border-bottom: 1px solid #0000001A;
}

.dashboard-queastions-answers .add-new-question {
    display: flex;
    flex-direction: column;
}

.dashboard-queastions-answers .add-new-question input,
.dashboard-queastions-answers .add-new-question button {
    width: 40% !important;
    display: block;
}

.dashboard-queastions-answers .add-new-question>div {
    margin-bottom: 15px;
    width: 90% !important;
}

.dashboard-queastions-answers .add-new-question input {
    all: unset;
    padding: 10px 5px;
    border: 1px solid #eee;
    font-size: 14px;
    border-radius: 8px;
    margin-bottom: 15px;
}

.dashboard-queastions-answers .add-new-question button {
    all: unset;
    background-color: #00BD99;
    color: #fff;
    text-align: center;
    cursor: pointer;
    border-radius: 8px;
    padding: 10px 0;
}

/* start question row */
.question-row {
    padding: 15px 10px;
    border-radius: 25px;
    background-color: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    width: 100%;
    /* z-index: 1; */
}

.question-row .right {
    width: 60%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.question-row .right p:first-child {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.question-row .right p:first-child>* {
    max-width: 100%;
}

.question-row .right p:first-child img {
    width: 50px !important;
    height: 50px !important;
}

.question-row .right>p {
    width: calc(95% / 3);
    border-left: 1px solid #eee;
    margin: 0;
}

.question-row .left button {
    all: unset;
    padding: 10px;
    margin-left: 15px;
    text-align: center;
    cursor: pointer;
}

.question-row .left button.delete i {
    color: #FF0101;
}

.question-row .left button.answers {
    padding: 8px 15px;
    text-align: center;
    border-radius: 5px;
    background-color: #0278FF;
    color: #fff;
}

/* start answer collaps */
.answers-collaps {
    padding: 0;
    border-radius: 24px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-bottom: 20px;
    height: 0;
    overflow: hidden;
    transition: all 0.5s;
    z-index: 2;
}

.answers-collaps.open {
    height: fit-content;
    padding: 15px 10px;
}

.answers-collaps .collaps-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.answers-collaps .collaps-header p {
    margin: 0;
}

.answers-collaps .collaps-header button {
    all: unset;
    padding: 8px 15px;
    cursor: pointer;
    color: #fff;
    background-color: #00BD99;
    text-align: center;
    border-radius: 8px;
}

.answers-collaps .collaps-header form input:not(input[type='checkbox']),
.answers-collaps .collaps-header form select {
    all: unset;
    padding: 8px 5px;
    margin-left: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 14px;
}

.answers-collaps .collaps-header form input[type='checkbox']+label,
.answers-collaps .collaps-header form input[type='checkbox'] {
    margin-left: 10px;
}

.answers-collaps .answers {
    padding: 15px 10px;
}

.answers-collaps .answers .answer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 24px;
}
.answers-collaps .answers .answer .check-correct {
    font-size: 2em;
}
.answers-collaps .answers .answer p {
    margin: 0;
    display: flex;
    align-items: center;
    width: 80%;
}

.answers-collaps .answers .answer p img {
    /* width: 60px; */
    height: 40px !important;
}
.answers-collaps .answers .answer .left button {
    all: unset;
    cursor: pointer;
    color: #FF0101;
    margin-left: 15px;
}
.answers-collaps .answers .answer .left button:nth-child(1) {
    color: #000;
}
.answers-collaps .answers p.empty {
    color: #bbbbbb;
}

/* end dashboard questions and answers page */
/* start dashboard subscriptions page */
.dashboard-subscriptions-invoices h1 {
    font-size: 32px;
    color: #1E1E1E;
    padding: 15px 0 25px 0;
    border-bottom: 1px solid #00000033;
}

.dashboard-subscriptions-invoices .totla-incom-subscription-count {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
}

.dashboard-subscriptions-invoices .totla-incom-subscription-count>div {
    width: 49%;
}

.dashboard-subscriptions-invoices .totla-incom-subscription-count .total-incom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 25px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 15px;
}

.dashboard-subscriptions-invoices .totla-incom-subscription-count .total-incom .total h6 {
    color: #000000;
    font-size: 20px;
}

.dashboard-subscriptions-invoices .totla-incom-subscription-count .total-incom .total p {
    font-size: 35.35px;
}

.dashboard-subscriptions-invoices .totla-incom-subscription-count .total-incom .total p span {
    color: #000000;
    font-size: 56.56px;
}

.dashboard-subscriptions-invoices .totla-incom-subscription-count .total-incom .image {
    width: 139px;
}

.dashboard-subscriptions-invoices .totla-incom-subscription-count .subscription-count {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-image: linear-gradient(#0278FF, #00C2FF);
    border-radius: 25px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 15px;
}

.dashboard-subscriptions-invoices .totla-incom-subscription-count .subscription-count .count h6 {
    color: #ffff;
    font-size: 20px;
}

.dashboard-subscriptions-invoices .totla-incom-subscription-count .subscription-count .count p {
    color: #ffff;
    font-size: 56.56px;
}

.dashboard-subscriptions-invoices .totla-incom-subscription-count .image {
    width: 88px;
}

.dashboard-subscriptions-invoices .totla-incom-subscription-count .image:not(:first-child) {
    margin-right: -15px;
}

.dashboard-subscriptions-invoices .page-title {
    color: #1E1E1E;
    padding: 15px 0;
    text-align: center;
    margin: 15px 0;
    border-top: 1px solid #0000001A;
    border-bottom: 1px solid #0000001A;
}

.dashboard-subscriptions-invoices .filter-invoices {
    padding: 15px 0;
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-bottom: 1px solid #0000001A;
}

.dashboard-subscriptions-invoices .filter-invoices .filter {
    width: 60%;
    display: flex;
    align-items: center;
    direction: ltr;
    background-color: #fff;
    border-radius: 24px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.dashboard-subscriptions-invoices .filter-invoices .filter button {
    all: unset;
    padding: 10px 25px;
    border-radius: 24px;
    background-image: linear-gradient(#0278FF, #00C2FF);
    color: #fff;
    cursor: pointer;
}

.dashboard-subscriptions-invoices .filter-invoices .filter .invoice-type {
    padding: 10px 10px;
}

.dashboard-subscriptions-invoices .filter-invoices .filter .invoice-type li {
    padding: 5px 20px;
    text-align: center;
    border: 1px solid #0000004D;
    margin-left: 15px;
    cursor: pointer;
    border-radius: 24px;
}

.dashboard-subscriptions-invoices .filter-invoices .date-filter {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0;
}

.dashboard-subscriptions-invoices .filter-invoices .date-filter .from,
.dashboard-subscriptions-invoices .filter-invoices .date-filter .to {
    width: 40%;
    display: flex;
    align-items: center;
}

.dashboard-subscriptions-invoices .filter-invoices .date-filter .from label,
.dashboard-subscriptions-invoices .filter-invoices .date-filter .to label {
    text-wrap: nowrap;
    margin-left: 10px;
    color: #1E1E1E;
    font-size: 15px;
}

.dashboard-subscriptions-invoices .filter-invoices .date-filter .from input,
.dashboard-subscriptions-invoices .filter-invoices .date-filter .to input {
    width: 100%;
}

.dashboard-subscriptions-invoices .filter-invoices .date-filter input {
    all: unset;
    padding: 10px;
    border: 1px solid #0000001A;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.dashboard-subscriptions-invoices .filter-invoices .date-filter .separate {
    width: 10%;
    border-top: 2px dotted #0278FF;
    position: relative;
}

.dashboard-subscriptions-invoices .filter-invoices .date-filter .separate::before {
    content: '\f0d9';
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    position: absolute;
    color: #0278FF;
    right: -3px;
    top: -12px;
}

.dashboard-subscriptions-invoices .filter-invoices .date-filter .separate::after {
    content: '';
    position: absolute;
    left: 0;
    top: -5px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #0278FF;
}

@media(max-width:767px) {

    .dashboard-subscriptions-invoices .totla-incom-subscription-count,
    .dashboard-subscriptions-invoices .filter-invoices .date-filter {
        flex-direction: column;
        margin-bottom: 15px;
    }

    .dashboard-subscriptions-invoices .totla-incom-subscription-count .total-incom {
        margin-bottom: 10px;
    }

    .dashboard-subscriptions-invoices .totla-incom-subscription-count .total-incom .total h6,
    .dashboard-subscriptions-invoices .totla-incom-subscription-count .subscription-count .count h6 {
        font-size: 12px;
    }

    .dashboard-subscriptions-invoices .totla-incom-subscription-count .total-incom .total p,
    .dashboard-subscriptions-invoices .totla-incom-subscription-count .total-incom .total p span,
    .dashboard-subscriptions-invoices .totla-incom-subscription-count .subscription-count .count p {
        font-size: 15px;
        font-weight: bold;
    }

    .dashboard-subscriptions-invoices .totla-incom-subscription-count>div,
    .dashboard-subscriptions-invoices .filter-invoices .filter,
    .dashboard-subscriptions-invoices .filter-invoices .date-filter,
    .dashboard-subscriptions-invoices .filter-invoices .date-filter * {
        width: 100% !important;
    }

    .dashboard-subscriptions-invoices .filter-invoices .date-filter>* {
        margin-bottom: 10px;
    }

    .dashboard-subscriptions-invoices .filter-invoices .filter .invoice-type {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .dashboard-subscriptions-invoices .filter-invoices .filter .invoice-type li {
        margin-bottom: 10px;
    }

    .dashboard-subscriptions-invoices .filter-invoices .date-filter .separate {
        display: none;
    }
}

/* start invoice row box */
.dashboard-subscriptions-invoices .invoices .add-new-invoice {
    padding: 15px 0;
}

.dashboard-subscriptions-invoices .invoices .add-new-invoice h6 {
    color: #1E1E1E;
    font-size: 15px;
}

.dashboard-subscriptions-invoices .invoices .add-new-invoice .new-invoice {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    margin-top: 15px;
    margin-bottom: 5px;
    border-radius: 25px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: #fff;
}

.dashboard-subscriptions-invoices .invoices .add-new-invoice .new-invoice>div {
    display: flex;
    flex-direction: column;
}

.dashboard-subscriptions-invoices .invoices .add-new-invoice .new-invoice>div label {
    font-size: 12px;
    padding: 0 0 10px 0;
}

.dashboard-subscriptions-invoices .invoices .add-new-invoice .new-invoice>div input {
    all: unset;
    padding: 8px;
    border-radius: 8px;
    background-color: #fff;
    border: 0.5px solid #000000;
}

.dashboard-subscriptions-invoices .invoices .add-new-invoice .new-invoice>div select {
    padding: 4px;
    margin: 0;
    border-radius: 8px;
    background-color: #fff;
    border: 0.5px solid #000000;
}

.dashboard-subscriptions-invoices .invoices .add-new-invoice .new-invoice .student-name {
    width: 30%;
}

.dashboard-subscriptions-invoices .invoices .add-new-invoice .new-invoice .course-name {
    width: 20%;
}

.dashboard-subscriptions-invoices .invoices .add-new-invoice .new-invoice button.save-data {
    all: unset;
    padding: 10px 40px;
    text-align: center;
    background-color: #00BD99;
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
}

.dashboard-subscriptions-invoices .invoices .invoice-row-box {
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 25px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dashboard-subscriptions-invoices .invoices .invoice-row-box .right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 70%;
}

.dashboard-subscriptions-invoices .invoices .invoice-row-box .right>div h6,
.dashboard-subscriptions-invoices .invoices .invoice-row-box .left .discount-code h6 {
    color: #00000080;
    font-size: 12px;
}

.dashboard-subscriptions-invoices .invoices .invoice-row-box .right>div p {
    color: #000;
    font-size: 16px;
    margin: 0;
}

.dashboard-subscriptions-invoices .invoices .invoice-row-box .right .student-name h6 {
    font-size: 20px;
    font-weight: 600;
}

.dashboard-subscriptions-invoices .invoices .invoice-row-box .right .student-name p {
    color: #00000080;
    font-size: 13px;
}

.dashboard-subscriptions-invoices .invoices .invoice-row-box .left .discount-code p {
    padding: 5px 20px;
    text-align: center;
    color: #0278FF !important;
    background-color: #0278FF33;
    border-radius: 24px;
    margin: 0;
}

.dashboard-subscriptions-invoices .invoices .invoice-row-box .left {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 25%;
}

.dashboard-subscriptions-invoices .invoices .invoice-row-box .left button {
    all: unset;
    cursor: pointer;
    font-size: 20px;
}

@media(max-width:767px) {
    .dashboard-subscriptions-invoices .invoices .add-new-invoice .new-invoice {
        flex-direction: column;
    }

    .dashboard-subscriptions-invoices .invoices .add-new-invoice .new-invoice * {
        width: 100% !important;
        padding-right: 0 !important;
        padding-left: 0 !important;
    }

    .dashboard-subscriptions-invoices .invoices .add-new-invoice .new-invoice>* {
        margin-bottom: 10px;
    }

    .dashboard-subscriptions-invoices .invoices .invoice-container {
        width: 100%;
        overflow-x: auto;
    }

    .dashboard-subscriptions-invoices .invoices .invoice-container .invoice-row-box {
        width: fit-content;
    }

    .dashboard-subscriptions-invoices .invoices .invoice-container .invoice-row-box * {
        text-wrap: nowrap;
        margin-left: 15px;
    }

    .dashboard-subscriptions-invoices .invoices .invoice-container .invoice-row-box .right {
        margin-left: 15px;
        width: fit-content !important;
    }
}

/* end dashboard subscriptions page */
/* start dashboard create copon page */
.dashboard-create-copon .page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 10px;
    border-bottom: 1px solid #0000001A;
}

.dashboard-create-copon .page-header h1 {
    padding: 0;
    margin: 0;
    font-size: 32px;
    color: #1E1E1E;
}

.dashboard-create-copon .page-header a.prev-copons {
    padding: 10px 30px;
    text-align: center;
    border-radius: 8px;
    border: 1px solid #00000033;
    font-size: 15px;
}

.dashboard-create-copon .create-new-copon {
    padding: 20px 0;
    border-bottom: 1px solid #0000001A;
}

.dashboard-create-copon .create-new-copon h6 {
    color: #000000;
    font-size: 13px;
}

.dashboard-create-copon .create-new-copon .new-copon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 15px;
    margin-top: 15px;
    margin-bottom: 5px;
    border-radius: 25px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: #fff;
}

.dashboard-create-copon .create-new-copon .new-copon .right {
    width: 70%;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
}

.dashboard-create-copon .create-new-copon .new-copon .right select {
    width: 40%;
    margin: 0;
    padding: 4px;
    border-radius: 8px;
    background-color: #fff;
    border: 0.5px solid #00000033;
    margin-left: 15px;
}

.dashboard-create-copon .create-new-copon .new-copon .right select.type {
    width: 20%;
}

.dashboard-create-copon .create-new-copon .new-copon .right input {
    text-align: center;
    all: unset;
    padding: 8px;
    border-radius: 8px;
    background-color: #fff;
    border: 0.5px solid #00000033;
}

.dashboard-create-copon .create-new-copon .new-copon .right input::placeholder {
    color: #00000066;
    text-align: center;
}

.dashboard-create-copon .create-new-copon .new-copon .left button.create {
    all: unset;
    padding: 10px 40px;
    text-align: center;
    background-color: #00BD99;
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
}

.dashboard-create-copon .resent-copons {
    padding: 15px 0;
}

.dashboard-create-copon .resent-copons .section-header {
    padding: 10px;
    direction: ltr;
    display: flex;
}

button.export-file {
    all: unset;
    padding: 10px;
    text-align: center;
    margin-right: 10px;
    cursor: pointer;
}

button.export-file {
    border: 1px solid #00000033;
    border-radius: 8px;
    display: flex;
    align-items: center;
    direction: ltr;
}

button.export-file i {
    margin-right: 10px;
}

.dashboard-create-copon .resent-copons .content {
    padding: 15px 0;
}

.dashboard-create-copon .resent-copons .content .copon-row {
    padding: 20px 15px;
    margin-bottom: 15px;
    border-radius: 25px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dashboard-create-copon .resent-copons .content .copon-row>* {
    width: calc(95% / 3);
    text-align: center;
}

.dashboard-create-copon .resent-copons .content .copon-row.student-code-row>* {
    width: calc(95% / 4);
}

.dashboard-create-copon .resent-copons .content .copon-row p {
    margin: 0;
}

.dashboard-create-copon .resent-copons .content .copon-row .code {
    padding: 5px 20px;
    color: #0278FF;
    background-color: #0278FF1A;
    border-radius: 8px;
    text-align: center;
    margin-left: 15px;
}

@media(max-width:767px) {
    .dashboard-create-copon .create-new-copon .new-copon {
        flex-direction: column;
    }

    .dashboard-create-copon .page-header a.prev-copons {
        font-size: 12px;
        padding: 10px;
    }

    .dashboard-create-copon .create-new-copon .new-copon>* {
        width: 100% !important;
        display: flex;
        flex-direction: column;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .dashboard-create-copon .create-new-copon .new-copon .right * {
        width: 100% !important;
        margin: 0 0 10px 0 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .dashboard-create-copon .resent-copons .content {
        width: 100%;
        overflow-x: auto;
    }
}

/* end dashboard create copon page */
/* start dashboard prev copons page */
.dashboard-prev-copons .page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 10px;
    border-bottom: 1px solid #0000001A;
}

.dashboard-prev-copons .page-header h1 {
    padding: 0;
    margin: 0;
    font-size: 32px;
    color: #1E1E1E;
}

.dashboard-prev-copons .filter {
    padding: 15px 10px;
}

.dashboard-prev-copons .filter select {
    display: block;
    width: 100%;
}

.dashboard-prev-copons .page-header a.create-copon {
    padding: 10px 30px;
    text-align: center;
    border-radius: 8px;
    border: 1px solid #00000033;
    font-size: 15px;
}

.dashboard-prev-copons .copons {
    padding: 20px 0;
}

.dashboard-prev-copons .copons .copon-row {
    padding: 20px 15px;
    margin-bottom: 15px;
    border-radius: 25px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dashboard-prev-copons .copons .copon-row .right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.dashboard-prev-copons .copons .copon-row .right>* {
    width: calc(95% / 4);
    text-align: center;
}

.dashboard-prev-copons .copons .copon-row .right p {
    width: calc(95% /3);
}

.dashboard-prev-copons .copons .copon-row .right p.code {
    padding: 5px 20px;
    margin-left: 30px !important;
    color: #0278FF;
    background-color: #0278FF1A;
    border-radius: 8px;
    text-align: center;
    margin-left: 15px;
}

.dashboard-prev-copons .copons .copon-row .left p {
    font-size: 16px;
    color: #000000;
    padding: 0 15px;
}

@media(max-width:767px) {
    .dashboard-prev-copons .page-header h1 {
        font-size: 20px;
    }

    .dashboard-prev-copons .page-header a.create-copon {
        font-size: 12px;
        padding: 10px;
        text-wrap: nowrap;
    }

    .dashboard-prev-copons .copons {
        width: 100%;
        overflow-x: auto;
    }

    .dashboard-prev-copons .copons .copon-row {
        width: fit-content;
    }

    .dashboard-prev-copons .copons .copon-row * {
        text-wrap: nowrap;
    }
}

/* end dashboard prev copons page */
/* start dashboard users page */
.dashboard-users-page h1 {
    font-size: 32px;
    color: #1E1E1E;
    padding: 15px 10px 30px;
    margin-bottom: 15px;
    border-bottom: 1px solid #00000033;
}

.dashboard-users-page .super-admins,
.dashboard-users-page .admins {
    padding: 15px 0;
}

.dashboard-users-page .section-header {
    display: flex;
    align-items: center;
}

.dashboard-users-page .section-header h6 {
    font-size: 15px;
    color: #1E1E1E;
    text-wrap: nowrap;
    margin-left: 15px;
    font-weight: 600;
}

.dashboard-users-page .section-header .line {
    background-color: #0000001A;
    width: 100%;
    height: 1px;
}

.dashboard-users-page>div .content {
    padding: 15px 0;
}

.dashboard-users-page>div .content .user-row {
    padding: 20px 15px;
    margin-bottom: 15px;
    border-radius: 25px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.dashboard-users-page>div .content .user-row .right {
    display: flex;
    align-items: center;
}

.dashboard-users-page>div .content .user-row .right .image {
    width: 57px;
    height: 57px;
    border-radius: 50%;
    overflow: hidden;
    margin-left: 20px;
}

.dashboard-users-page>div .content .user-row .right .user-info p {
    margin: 0;
    color: #1E1E1E;
    font-size: 13px;
}

.dashboard-users-page>div .content .user-row .left button {
    all: unset;
    padding: 10px;
    margin-left: 15px;
    text-align: center;
    cursor: pointer;
}

.dashboard-users-page>div .content .user-row .left button.edit i {
    color: #000000CC;
}

.dashboard-users-page>div .content .user-row .left button.delete i {
    color: #FF3838;
}

@media(max-width:767px) {
    .dashboard-users-page>div .content {
        width: 100%;
        overflow-x: auto;
    }

    .dashboard-users-page>div .content .user-row {
        width: fit-content;
    }

    .dashboard-users-page>div .content .user-row .left {
        display: flex;
        margin-right: 15px;
    }
}

.dashboard-users-page .create-new {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dashboard-users-page .create-new .line {
    width: 1.5px;
    height: 47px;
    position: relative;
    background-color: #0278FF;
    margin-bottom: 10px;
}

.dashboard-users-page .create-new .line::before,
.dashboard-users-page .create-new .line::after {
    font-family: "Font Awesome 6 Free";
    font-weight: 900;
    position: absolute;
    color: #0278FF;
}

.dashboard-users-page .create-new .line::before {
    content: '\f0dd';
    top: -13px;
    right: -4px;
}

.dashboard-users-page .create-new .line::after {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #0278FF;
    bottom: 0;
    right: -3px;
}

.dashboard-users-page .create-new a {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #0278FF !important;
    font-size: 18px;
}

.dashboard-users-page .create-new a .icon {
    color: #fff;
    background-color: #0278FF;
    padding: 8px 15px;
    border-radius: 8px;
    text-align: center;
    margin-bottom: 10px;
}

@media(max-width:767px) {}

/* end dashboard users page */
/* start dashboard add user page */
.dashboard-add-user-page h1 {
    font-size: 32px;
    color: #1E1E1E;
    padding: 15px 10px 30px;
    margin-bottom: 15px;
    border-bottom: 1px solid #00000033;
}

.dashboard-add-user-page .add-user {
    width: 70%;
    background-color: #fff;
    border-radius: 24px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 15px;
    display: flex;
}

.dashboard-add-user-page .add-user .right {
    width: 20%;
    border-left: 1px solid #0000001A;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dashboard-add-user-page .add-user .right .image {
    width: 116px;
    height: 116px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 15px;
}

.dashboard-add-user-page .add-user .right label {
    padding: 5px 15px;
    text-align: center;
    margin-bottom: 15px;
    cursor: pointer;
    color: #0278FF;
}

.dashboard-add-user-page .add-user .right label i,
.dashboard-add-user-page .add-user .right .remove-image i {
    margin-left: 10px;
}

.dashboard-add-user-page .add-user .right .remove-image {
    all: unset;
    padding: 5px 15px;
    text-align: center;
    color: #FF3838;
    cursor: pointer;
}

@media(max-width:767px) {
    .dashboard-add-user-page .add-user {
        flex-direction: column;
        width: 100%;
    }

    .dashboard-add-user-page .add-user .right {
        width: 100%;
        border-left: none !important;
        margin-bottom: 15px !important;
    }
}

.dashboard-add-user-page .add-user .left {
    width: 100%;
    padding: 0 15px;
}

.dashboard-add-user-page .add-user .left h6,
.dashboard-add-user-page .add-user .left .permissions h6,
.dashboard-add-user-page .add-user .left .account-type h6 {
    color: #1E1E1E;
    font-size: 13px;
    margin-bottom: 15px;
    padding: 10px 0;
}

.dashboard-add-user-page .add-user .left .inputs {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 0 20px 0;
    border-bottom: 1px solid #0000001A;
}

.dashboard-add-user-page .add-user .left .inputs>div {
    display: flex;
    flex-direction: column;
    width: 49%;
    margin-bottom: 15px;
}

.dashboard-add-user-page .add-user .left .inputs>div label {
    margin-bottom: 10px;
    color: #1E1E1ECC;
    font-size: 13px;
}

.dashboard-add-user-page .add-user .left .inputs>div input {
    all: unset;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #00000080;
    width: 95%;
}

.dashboard-add-user-page .add-user .left .inputs>div input::placeholder {
    color: #1E1E1E80;
}

.dashboard-add-user-page .add-user .left .inputs>div input:focus::placeholder {
    color: transparent;
}

.dashboard-add-user-page .add-user .left .permissions h6 {
    padding: 30px 0 10px 0;
}

.dashboard-add-user-page .add-user .left .permissions {
    width: 90%;
    padding: 0 0 20px 0;
    border-bottom: 1px solid #0000001A;
}

.dashboard-add-user-page .add-user .left .permissions ul,
.dashboard-add-user-page .add-user .left .account-type ul {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    flex-wrap: wrap;
}

.dashboard-add-user-page .add-user .left .permissions ul li,
.dashboard-add-user-page .add-user .left .account-type ul li {
    width: 30%;
    padding: 10px 0;
    text-align: center;
    border: 1px solid #00000080;
    margin-bottom: 15px;
    margin-left: 3%;
    border-radius: 8px;
    cursor: pointer;
}

.dashboard-add-user-page .add-user .left .permissions ul li i {
    margin-left: 10px;
}

.dashboard-add-user-page .add-user .add-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0 20px 0;
}

.dashboard-add-user-page .add-user .add-btn button {
    all: unset;
    padding: 10px 30px;
    text-align: center;
    background-color: #0CBC8B !important;
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
}

@media(max-width:767px) {
    .dashboard-add-user-page .add-user .left .inputs>div {
        width: 100% !important;
    }

    .dashboard-add-user-page .add-user .left .account-type ul {
        justify-content: space-between;
    }

    .dashboard-add-user-page .add-user .left .account-type ul li {
        text-wrap: nowrap;
        width: 49%;
        margin: 0;
    }
}

/* end dashboard add user page */
/* start dashboard notifications page */
.dashboard-notifications-page h1 {
    font-size: 32px;
    color: #1E1E1E;
    padding: 15px 10px 30px;
    margin-bottom: 15px;
    border-bottom: 1px solid #00000033;
}

.dashboard-notifications-page .new-notification {
    padding: 0 0 40px 0;
    border-bottom: 1px solid #00000033;
}

.dashboard-notifications-page .new-notification h6 {
    color: #1E1E1E;
    font-size: 13px;
    padding: 15px 0;
}

.dashboard-notifications-page .new-notification .notification-data {
    width: 80%;
    padding: 15px;
    background-color: #fff;
    border-radius: 24px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.dashboard-notifications-page .new-notification .notification-data .top {
    display: flex;
    border-bottom: 1px solid #0000001A;
    padding: 10px 0 30px 0;
}

.dashboard-notifications-page .new-notification .notification-data .top>div {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
}

.dashboard-notifications-page .new-notification .notification-data .top>div:nth-child(1),
.dashboard-notifications-page .new-notification .notification-data .top>div:nth-child(3) {
    width: 20%;
}

.dashboard-notifications-page .new-notification .notification-data .top>div:nth-child(2) {
    width: 50%;
    justify-content: end;
}

.dashboard-notifications-page .new-notification .notification-data .top>div label {
    color: #1E1E1E99;
    font-size: 13px;
    margin-bottom: 10px;
}

.dashboard-notifications-page .new-notification .notification-data .top>div select {
    padding: 5px;
    background-color: #fff;
    border: 1px solid #00000033;
    margin: 0;
}

.dashboard-notifications-page .new-notification .notification-data .top>div input {
    all: unset;
    padding: 10px 5px;
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #00000033;
}

.dashboard-notifications-page .new-notification .notification-data .middle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #0000001A;
    padding: 10px 0 30px 0;
}

.dashboard-notifications-page .new-notification .notification-data .middle>div:nth-child(1) {
    width: 20%;
}

.dashboard-notifications-page .new-notification .notification-data .middle>div:nth-child(2) {
    width: 45%;
}

.dashboard-notifications-page .new-notification .notification-data .middle>div:nth-child(3) {
    width: 35%;
}

.dashboard-notifications-page .new-notification .notification-data .middle>div {
    width: 30%;
    padding: 10px 15px;
}

.dashboard-notifications-page .new-notification .notification-data .middle>div:not(:last-child) {
    border-left: 1px solid #0000001A;
}

.dashboard-notifications-page .new-notification .notification-data .middle>div p {
    color: #1E1E1E;
    font-size: 13px;
}

.dashboard-notifications-page .new-notification .notification-data .middle>div ul {
    display: flex;
    align-items: center;
}

.dashboard-notifications-page .new-notification .notification-data .middle>div ul li {
    padding: 10px 30px;
    cursor: pointer;
    border-radius: 8px;
    border: 1px solid #00000080;
    color: #1E1E1E;
    font-size: 13px;
    text-align: center;
    margin-left: 10px;
    text-wrap: nowrap;
}

.dashboard-notifications-page .new-notification .notification-data .bottom {
    padding: 15px 0;
    display: flex;
    flex-direction: column;
}

.dashboard-notifications-page .new-notification .notification-data .bottom label {
    font-size: 13px;
    color: #1E1E1E99;
    padding: 10px 0;
}

.dashboard-notifications-page .new-notification .notification-data .bottom textarea {
    all: unset;
    background-color: #F8F8F8;
    border: 1px solid #00000033;
    border-radius: 15px;
    height: 169px;
    padding: 10px;
}

.dashboard-notifications-page .new-notification .notification-data .create {
    padding: 30px 0 15px 0;
    display: flex;
    justify-content: center;
}

.dashboard-notifications-page .new-notification .notification-data .create button {
    all: unset;
    background-color: #0CBC8B;
    color: #fff;
    padding: 8px 30px;
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
}

.dashboard-notifications-page .notifications {
    padding: 15px 0;
}

.dashboard-notifications-page .notifications h6 {
    color: #1E1E1E;
    font-size: 13px;
}

@media(max-width:767px) {
    .dashboard-notifications-page h1 {
        font-size: 20px;
    }

    .dashboard-notifications-page .new-notification .notification-data {
        width: 100% !important;
    }

    .dashboard-notifications-page .new-notification .notification-data>div,
    .dashboard-notifications-page .new-notification .notification-data .middle>div ul {
        display: flex;
        flex-direction: column;
    }

    .dashboard-notifications-page .new-notification .notification-data .middle>div {
        border-left: none !important;
    }

    .dashboard-notifications-page .new-notification .notification-data .top>div input,
    .dashboard-notifications-page .new-notification .notification-data .bottom textarea {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .dashboard-notifications-page .new-notification .notification-data>div * {
        width: 100% !important;
        margin-bottom: 15px;
    }

    .dashboard-notifications-page .new-notification .notification-data .create button {
        width: 100%;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

/* start notifications section */
.dashboard-notifications-page .notification-section {
    padding: 15px 0;
}

.dashboard-notifications-page .notification-section h6 {
    padding: 10px 0;
    text-align: center;
    color: #1E1E1E80;
    font-size: 13px;
}

.dashboard-notifications-page .notification-section .notification-row-box {
    display: flex;
    background-color: #fff;
    border-radius: 24px;
    margin-bottom: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 15px;
}

.dashboard-notifications-page .notification-section .notification-row-box .image {
    width: 12%;
    margin-left: 15px;
}

.dashboard-notifications-page .notification-section .notification-row-box .title {
    font-size: 18px;
    text-align: start;
    color: #000000;
    margin: 0;
    padding: 5px 0;
}

.dashboard-notifications-page .notification-section .notification-row-box .content {
    font-size: 12px;
    margin-bottom: 10px;
}

.dashboard-notifications-page .notification-section .notification-row-box .date {
    font-size: 12px;
    color: #000000CC;
}

/* end dashboard notifications page */
/* start dashboard attachments page */
.dashboard-attachments-page h1 {
    font-size: 32px;
    color: #1E1E1E;
    padding: 15px 10px 30px;
    margin-bottom: 15px;
    border-bottom: 1px solid #00000033;
}

.dashboard-attachments-page .attachment-section {
    padding: 15px 0;
}

.dashboard-attachments-page .attachment-section .title {
    display: flex;
    align-items: center;
}

.dashboard-attachments-page .attachment-section .title h6 {
    color: #1E1E1E;
    font-size: 20px;
    text-wrap: nowrap;
    margin: 0 0 0 10px;
    padding: 10px 0;
}

.dashboard-attachments-page .attachment-section .title .separate {
    height: 1px;
    width: 100%;
    background-color: #0000001A;
}

.dashboard-attachments-page .attachment-section .attach-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 20px 0;
}

.dashboard-attachments-page .attachment-section .attach-container .attachment-box {
    padding: 15px;
    border-radius: 24px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-bottom: 15px;
    width: 49%;
    margin-left: 1%;
    display: flex;
    background-color: #fff;
}

.dashboard-attachments-page .attachment-section .attach-container .attachment-box .image {
    width: 35%;
    height: 225px;
    border-radius: 15px;
    overflow: hidden;
    border: 1px solid #00000033;
    margin-left: 10px;
}

.dashboard-attachments-page .attachment-section .attach-container .attachment-box .data {
    width: 100%;
}

.dashboard-attachments-page .attachment-section .attach-container .attachment-box .data .title {
    font-size: 16px;
    color: #1E1E1E;
    padding: 15px 0;
}

.dashboard-attachments-page .attachment-section .attach-container .attachment-box .data .content {
    font-size: 12px;
    color: #414141;
    /* width: 60%; */
    margin-bottom: 30px !important;
}

.dashboard-attachments-page .attachment-section .attach-container .attachment-box .data .price {
    font-size: 16px;
    color: #1E1E1E;
    font-weight: bold;
}

.dashboard-attachments-page .attachment-section .attach-container .attachment-box .settings {
    padding: 5px 0;
    display: flex;
}

.dashboard-attachments-page .attachment-section .attach-container .attachment-box .settings button {
    all: unset;
    width: 23px;
    height: 23px;
    padding: 5px;
    border-radius: 50%;
    background-color: #ECECEC;
    cursor: pointer;
    text-align: center;
    margin-right: 10px;
}

@media(max-width:767px) {
    .dashboard-attachments-page h1 {
        font-size: 20px;
    }

    .dashboard-attachments-page .attachment-section .attach-container .attachment-box {
        width: 100%;
        flex-direction: column;
        position: relative;
    }

    .dashboard-attachments-page .attachment-section .attach-container .attachment-box .image {
        width: 100%;
    }

    .dashboard-attachments-page .attachment-section .attach-container .attachment-box .settings {
        position: absolute;
        top: 10px;
        left: 10px;
        padding: 5px;
        border-radius: 24px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        background-color: #fff;
    }

    .dashboard-attachments-page .attachment-section .attach-container .attachment-box .settings button:nth-child(1) {
        margin-right: 0 !important;
    }
}

/* end dashboard attachments page */
/* start course subscription */
.search-bar {
    padding: 15px 10px;
    display: flex;
    margin-bottom: 15px;
}

.search-bar select:first-child {
    all: unset;
    padding: 15px 10px;
    /* background-color: #ccc; */
    border: 1px solid #eee;
    border-radius: 24px;
    margin-left: 15px;
}

.search-bar input,
.search-bar select:nth-child(2) {
    all: unset;
    padding: 10px;
    display: block;
    width: 100%;
    border: 1px solid #eee;
    border-radius: 0 24px 24px 0;
}

.search-bar input::placeholder {
    color: #ccc;
}

.search-bar button {
    all: unset;
    padding: 10px 15px;
    background-color: #00BD99;
    color: #fff;
    border-radius: 24px 0 0 24px;
    cursor: pointer;
}

.course-subscriptions tbody button {
    all: unset;
    padding: 5px 15px;
    text-align: center;
    color: #fff;
    cursor: pointer;
    background-color: #FF0101;
    border-radius: 8px;
}

.course-subscriptions thead tr th,
.course-subscriptions tbody tr td {
    width: calc(95% / 7) !important;
}

.course-subscriptions tbody tr {
    margin: 0;
}

.course-subscriptions tbody button.active {
    background-color: #00BD99;
}

.course-subscriptions tbody tr.collabs {
    transition: all 0.5s;
    background-color: #eee;
    padding: 25px 10px;
    margin-bottom: 15px;
    margin-top: 5px;
    border-radius: 8px;
}

.course-subscriptions tbody tr.collabs td {
    width: calc(95% / 5) !important;
    text-align: center !important;
}

.course-subscriptions tbody tr.collabs.close {
    height: 0;
    overflow: hidden;
    padding: unset;
}

/* end course subscription */
/* start pagination btn */
.pagination-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px 0;
    margin-top: 15px;
}

.pagination-btn button {
    all: unset;
    cursor: pointer;
    padding: 5px 15px;
    border-radius: 5px;
    margin: 0 5px;
}

.pagination-btn button.prev {
    background-color: #eee;
    color: #000;
}

.pagination-btn button.next {
    background-color: #0278FF;
    color: #fff;
}

.pagination-btn button.disable {
    cursor: no-drop;
    opacity: 0.5;
}

/* end pagination btn */
/* start dashboard course files */
.course-files-page h1 {
    padding: 10px 15px;
    margin-bottom: 15px;
}

.course-files-page .add-files {
    padding: 15px 10px 25px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #EFEFEF;
}

.course-files-page .add-files>div {
    width: 50%;
    margin-left: 10px;
}

.course-files-page .add-files button {
    all: unset;
    padding: 5px 15px;
    border-radius: 8px;
    text-align: center;
    background-color: #00BD99;
    color: #fff;
    cursor: pointer;
    display: block;
}

.course-files-page .files {
    padding: 15px 10px;
}

.course-files-page .files .cousre-file-box {
    padding: 10px;
    border-radius: 24px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.course-files-page .files .cousre-file-box .icon {
    font-size: 30px;
    text-align: center;
    padding: 15px 0;
    color: #f1c40f;
}

.course-files-page .files .cousre-file-box .box-footer button {
    all: unset;
    cursor: pointer;
    padding: 8px 0;
    text-align: center;
    display: block;
    background-color: #FF0101;
    color: #fff;
    border-radius: 8px;
    width: 100%;
    transition: background-color 0.5s;
}

.course-files-page .files .cousre-file-box .box-footer button:hover {
    background-color: #FF3838;
}

/* end dashboard course files */
/* start lesson views page */
.lesson-views-page h3 {
    padding: 15px 10px;
}

.lesson-views-page .content,
.lesson-views-page .content * {
    display: block;
}

.lesson-views-page .content thead {
    width: 100%;
    background-color: #CFCFCF;
    padding: 15px 0;
    border-radius: 24px;
    margin-bottom: 15px;
}

.lesson-views-page .content thead tr,
.lesson-views-page .content tbody tr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.lesson-views-page .content thead tr th,
.lesson-views-page .content tbody tr td {
    width: calc(95%/8);
    text-align: center;
}

.lesson-views-page .content tbody {
    width: 100%;
    background-color: #F8F9FB;
    border-radius: 8px;
    padding: 15px 10px;
}

.lesson-views-page .content tbody tr {
    padding: 15px 0;
    background-color: #fff;
    border-radius: 24px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.lesson-views-page .content tbody tr td .update {
    all: unset;
    cursor: pointer;
    padding: 8px 15px;
    text-align: center;
    background-color: #f1c40f;
    color: #fff;
    border-radius: 8px;
}

.lesson-views-page .content tbody tr td.view-count {
    display: flex;
    align-items: center;
    border: 1px solid #bbbbbb;
    padding: 5px 0;
    border-radius: 8px;
}

.lesson-views-page .content tbody tr td.view-count input {
    all: unset;
    display: block;
    width: 80%;
    height: 100% !important;
    text-align: center;
}

.lesson-views-page .content tbody tr td.view-count .btns {
    width: 10%;
    display: flex;
    flex-direction: column;
}

.lesson-views-page .content tbody tr td.view-count .btns button {
    all: unset;
    cursor: pointer;
    display: block;
}

.lesson-views-page .content tbody tr td.view-count .btns button.plus {
    margin-bottom: 10px;
}

/* end lesson views page */
/* start quize page */
.dashboard-quizes-page h1 {
    font-size: 32px;
    color: #1E1E1E;
    padding: 15px 10px 30px;
    margin-bottom: 15px;
    border-bottom: 1px solid #00000033;
}

.dashboard-quizes-page .create-new-quiz-form {
    padding: 15px 10px;
    border-bottom: 1px solid #00000033;
}

.dashboard-quizes-page .create-new-quiz-form input {
    /* all: unset; */
    padding: 10px;
    display: block;
    border: 1px solid #eee;
    border-radius: 8px;
}

.dashboard-quizes-page .create-new-quiz-form button {
    all: unset;
    padding: 10px 15px;
    background-color: #00BD99;
    color: #fff;
    border-radius: 8px;
    cursor: pointer;
}

.dashboard-quizes-page .all-quizes .content,
.dashboard-quizes-page .all-quizes .content * {
    display: block;
}

.dashboard-quizes-page .all-quizes .content thead {
    width: 100%;
    background-color: #CFCFCF;
    padding: 15px 0;
    border-radius: 24px;
    margin-bottom: 15px;
}

.dashboard-quizes-page .all-quizes .content thead tr,
.dashboard-quizes-page .all-quizes .content tbody tr {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.dashboard-quizes-page .all-quizes .content thead tr th,
.dashboard-quizes-page .all-quizes .content tbody tr td {
    width: calc(95%/8);
    text-align: center;
    font-size: 13px;
}

.dashboard-quizes-page .all-quizes .content tbody {
    width: 100%;
    background-color: #F8F9FB;
    border-radius: 8px;
    padding: 15px 10px;
}

.dashboard-quizes-page .all-quizes .content tbody tr {
    padding: 15px 0;
    background-color: #fff;
    border-radius: 24px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    margin-bottom: 15px;
}

.dashboard-quizes-page .all-quizes .content tbody tr td.controles {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
}

.dashboard-quizes-page .all-quizes .content tbody tr td a,
.dashboard-quizes-page .all-quizes .content tbody tr td button.delete {
    all: unset;
    padding: 5px 10px;
    color: #fff;
    text-align: center;
    border-radius: 8px;
    cursor: pointer;
}

.dashboard-quizes-page .all-quizes .content tbody tr td a {
    background-color: #0278FF;
    margin-bottom: 10px;
}

.dashboard-quizes-page .all-quizes .content tbody tr td button.delete {
    background-color: #FF0101;
}
.students-info tbody tr td button.delete {
    all: unset;
    padding: 5px;
    color: #FF0101;
    cursor: pointer;
    font-size: 1.5em;
}
/* end quize page */
/* start add new answer popup */
.add-new-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0000000c;
    z-index: 999999;
    justify-content: center;
    align-items: center;
    display: none;
    overflow: auto;
}

.add-new-popup.open {
    display: flex;
}

.add-new-popup .new-box {
    padding: 15px;
    border-radius: 24px;
    background-color: #fff;
    width: 60%;
}

.add-new-popup .new-box>div.text {
    max-width: 100% !important;
    min-width: unset !important;
}

.add-new-popup .new-box button {
    all: unset;
    padding: 10px 0;
    text-align: center;
    width: 100%;
    cursor: pointer;
    color: #fff;
    background-color: #00C2FF;
    border-radius: 8px;
}

.add-new-popup .new-box .check {
    padding: 15px;
    margin: 10px 0;
}

.add-new-popup .new-box .check input {
    margin-left: 15px;
}

/* end add new answer popup */
/* start dashboard all exams page */
.dashboard-all-exams-page h1 {
    padding: 15px 10px;
    margin-bottom: 15px;
}
.dashboard-all-exams-page .exams-search{
    padding: 15px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.dashboard-all-exams-page .exams-search select , 
.dashboard-all-exams-page .exams-search button{
    all: unset;
    padding: 15px 5px;
    width: calc(90% / 3);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 14px;
    margin-bottom: 10px;
}
.dashboard-all-exams-page .exams-search button {
    text-align: center;
    background-color: #00BD99;
    cursor: pointer;
    color: #fff;
}
/* end dashboard all exams page */
.student-answer > div {
    padding: 15px;
    background-color: #fff;
    border-radius: 24px;
    width: 80%;
}
.student-answer > div h3 {
    text-align: center;
}
.student-answer > div .answers {
    padding: 15px 0;
}
.student-answer > div .answers .answer-box {
    padding: 15px 0;
}
.student-answer > div .answers .answer-box h5 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.student-answer > div .answers .answer-box h5 img {
    /* width: 50% !important; */
    height: 100px !important;
}
.student-answer > div .answers .answer-box ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px 0;
}
.student-answer > div .answers .answer-box ul li {
    width: calc(95% / 2);
    border-radius: 24px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 15px;
    display: flex;
    align-items: center;
}
.student-answer > div .answers .answer-box .answer-title {
    padding: 15px 0;
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.student-answer > div .answers .answer-box .answer-title p {
    margin: 0;
    width: calc(95% / 2);
    text-align: center;
}
.student-answer > div .answers .answer-box ul li.wrong {
    background-color: #FF3838;
}
.student-answer > div .answers .answer-box ul li.wrong > * {
    color: #fff;
}
.student-answer > div .answers .next-prev {
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.student-answer > div .answers .next-prev button {
    all: unset;
    padding: 10px;
    cursor: pointer;
    border-radius: 24px;
    text-align: center;
}
.student-answer > div .answers .next-prev .prev {
    margin-left: 15px;
    background-color: #1E1E1E;
    color: #fff;
}
.student-answer > div .answers .next-prev .next {
    background-color: #0278FF;
    color: #fff;
}
/* dashboard start show student exam answers */

.dashboard-show-student-answers h2 {
    padding: 20px 0;
    text-align: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.dashboard-show-student-answers .answers {
    padding: 15px;
}
.dashboard-show-student-answers .answers .answer-box{
    padding: 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.dashboard-show-student-answers .answers .answer-box .question-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 200px;
    margin-bottom: 10px;
}
.dashboard-show-student-answers .answers .answer-box .question-title img {
    height: 200px
}
.dashboard-show-student-answers .answers .answer-box .title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}
.dashboard-show-student-answers .answers .answer-box .title p {
    width: calc(70% / 2);
    text-align: center;
    border-radius: 14px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 10px 0;
}
.dashboard-show-student-answers .answers .answer-box .title p:first-child{
    margin-left: 15px;
}
.dashboard-show-student-answers .answers .answer-box .student-answer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
}
.dashboard-show-student-answers .answers .answer-box .student-answer li {
    width: calc(70% / 2);
    text-align: center;
    border-radius: 14px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 10px 0;
}
.dashboard-show-student-answers .answers .answer-box .student-answer li:first-child{
    margin-left: 15px;

}
.dashboard-show-student-answers .answers .answer-box .student-answer li.wrong {
    background-color: #FF3838;
}
.dashboard-show-student-answers .answers .answer-box .student-answer li.wrong * , 
.dashboard-show-student-answers .answers .answer-box .student-answer li.right * {
    color: #fff;
}
.dashboard-show-student-answers .answers .answer-box .student-answer li.right {
    background-color: #00BD99;
}
/* dashboard end show student exam answers */
/* pagination link */
.paegination-links ul {
    display: flex;
    justify-content: center;
    align-items: center;
}
.paegination-links ul li {
    padding: 5px;
    cursor: pointer;
}
.paegination-links ul li:not(:last-child){
    margin-left: 10px;
}
.paegination-links ul li.active {
    background-color: #0278FF;
    color: #fff;
    border-radius: 8px;
}
/* pagination link */
/* start course subscriptions */
.course-subscriptions .section-header{
    padding: 20px 15px;
    margin-bottom: 15px;
    
}
/* end course subscriptions */
.dashboard-upload-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.dashboard-upload-header p{
    margin: 0;
}
.dashboard-upload-header button {
    all: unset;
    padding: 8px 15px;
    border: 1px solid #FF0101;
    color: #FF0101;
    text-align: center;
    transition: all 0.5s;
    cursor: pointer;
    border-radius: 8px;
}
.dashboard-upload-header button:hover{
    color: #fff;
    background-color: #FF0101;
}
.upload-progress {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    width: 100%;
}
.upload-progress .text {
    width: 10%;
}
.upload-progress .bar {
    width: 85%;
    height: 4px;
    background-color: #1e1e1e77;
}
.upload-progress .bar >div {
    width: 0;
    height: 100%;
    background-color: #00BD99;
}
/* start lesson views search */
.leasson-views-search {
    padding: 15px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.leasson-views-search select {
    all: unset;
    padding: 10px;
    border: 1px solid #4141418a;
    border-radius: 24px;
}
.leasson-views-search .search-input {
    display: flex;
    align-items: center;
    border: 1px solid #4141418a;
    border-radius: 24px;
    overflow: hidden;
    width: 88%;
}
.leasson-views-search .search-input input , 
.leasson-views-search .search-input button {
    all: unset;
    padding: 10px;
}
.leasson-views-search .search-input input{
    width: 95%;
}
.leasson-views-search .search-input button {
    cursor: pointer;
    background-color: #00BD99;
    color: #fff;
    width: 5%;
    text-align: center;
}
/* end lesson views search */
/* start update lesson section */
.update-lesson {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #F2F2F2;
    border-radius: 14px;
    overflow: hidden;
    height: 0;
    transition: all 0.5s;
}
.update-lesson.open {
    height: fit-content;
}
.update-lesson .right {
    width: 30%;
    padding: 10px;
}
.update-lesson .right video {
    width: 100% !important;
}
.update-lesson .left {
    width: 65%;
    padding: 10px;
}
.update-lesson .left .group ,
.update-lesson .right .group {
    display: flex;
    flex-direction: column;
}
.update-lesson .left .group label ,
.update-lesson .right .group label {
    padding: 15px 0;
}
.update-lesson .left .group input ,
.update-lesson .right .group input,
.update-lesson .left .group textarea {
    all: unset;
    display: block;
    padding: 10px;
    border: 1px solid #bbbbbb;
    border-radius: 14px;
}
.update-lesson .left button ,
.update-lesson .right button  {
    all: unset;
    background-color: #f1c40f;
    padding: 10px;
    text-align: center;
    display: block;
    border-radius: 14px;
    cursor: pointer;
    margin-top: 10px;
}
.update-lesson .right input ,
.update-lesson .right button {
    display: block;
    width: 100%;
}
/* end update lesson section */