.about{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-evenly;
    align-items: center;
    overflow: hidden;
    margin-top: 20px;
    margin-bottom: 30px;
}
.about .about-left{
    width:40%;
}
.about .about-left .banner{
    width:24%;
    position: absolute;
    left:0px;
    top: 20px;
    animation: banner 8s 0s infinite;
    z-index:20!important;
}
@keyframes banner {
    0%{
           left: 0px;
    }
    50%{
            left:40px;
    }
    100%{
        left:0px;
    }
}
.about .about-left img{
    z-index: 10000!important;
    width: 100%;
 
}
.about .about-right{
    display: flex;
    margin-right: 10px;
    flex-direction: column;
    color: rgb(0, 0, 0);
    width:45%;
    margin-right: 20px;

}
.about-right h1{
font-size: 41px;
line-height: 1.9;
font-weight: 900;
}
.about-right p{
line-height: 1.9;
}
.about-right .marker{
    color:rgb(248, 29, 29);
}.advent{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap!important;
    justify-content: space-between;
    align-items: center;
    gap:0px;
    margin: auto;
    margin-top: 20px;
}

.about-right .prp{
    margin-top: 10px;
    width:48%;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items:center;
    background-color: white;
    border-radius: 20px;
    gap:5px;
    line-height: 1.7;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
    font-size: 30px;
    gap: 20px;
    margin-top: 40px!important;
}
.about-right .prp:hover{
transition: 0.5s;
background-color: rgb(235, 68, 68)!important;
color: white;
cursor: pointer;
}
.about-right .onee{
    width: 100%;
    margin: auto;
}
.prp .iconn{
    color: rgb(243, 16, 27);
    font-size:25px;
}