.advert{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0px;
    overflow: hidden;
    width: 90%;
    margin: auto!important;
}
.advert-left{
    width:50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.advert-left img{
    width: 300px;
}
.advert-left h1{
    font-size: 40px;
    font-weight: 800;
    margin-bottom: 20px;
    line-height: 1.8;
}
.advert-left p{
    line-height: 1.8;
    border-left: 5px solid rgb(235, 71, 71);
}
.advert-right{
    width:40%;
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 20px;
}
.advert-right .adv{
    width: 70%;
    margin: auto;
    background-color:rgb(249, 249, 249);
    padding: 20px;
    border-radius: 20px;
    color: rgb(19, 14, 14);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 20px;
    cursor: pointer;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}.advert-right .adv:hover{
background-color:rgb(240, 63, 75)!important;
transition: 0.5s;
color: white;
}
.adv .dev-icon{
    font-size: 40px;
    color:rgb(237, 55, 55);
}
.advert-right .adv:hover .dev-icon{
color:rgb(255, 255, 255);
transition: 0.5s;
}