.prop{
    overflow: hidden;
    padding: 70px 0px!important;
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 40px;
        overflow: hidden;
    border-radius: 20px;
    background-image: url('../../../../Components/../assets/mo-abdalmagid/einstien.jpg');
    background-image:linear-gradient(rgba(230,20,20,.7), rgba(10,20,20,.7)),url('../../../../assets/mo-abdalmagid/einstien.jpg')!important;
    background-size: 100%;
}
.prop .prop-left{
    color: aliceblue;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content:start;
    gap: 30px;
    
}
.prop .prop-left .pr{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}
.prop .prop-left .pr  h2{
    font-size:25px!important;
}
.prop .btnn{
    background-color: rgb(232, 16, 16);
    color: white;
    padding: 10px;
    font-size: 20px;
    border-radius: 10px;
}
.prop .btnn:hover{
background-color: transparent;
transition: 0.5s;
border: 2px solid red;
}
.pr-icon{
    font-size:20px;
}