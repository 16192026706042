/* start taha home */
.taha-home {
  height: 100vh;
  background-image: url("../../../assets/taha-assets/hero-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 10px;
}

.taha-home .banner {
  height: 100%;
  display: flex;
  align-items: end;
  justify-content: space-around;
}

.taha-home .banner .image,
.taha-home .banner .text {
  width: 40%;
}

.taha-home .banner .text,
.taha-home .banner .image {
  display: flex;
  align-items: center;
  height: 100%;
  position: relative;
  z-index: 1;
}

.taha-home .banner .text .box {
  width: 90%;
  padding: 15px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 24px 0 24px 0;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.taha-home .banner .text .box h1 {
  font-size: 2.5em;
  font-weight: bold;
  margin-bottom: 20px;
  color: var(--text-color);
  position: relative;
  transition: all 0.3s ease-out 0s;
  z-index: 1;
  background: linear-gradient(#0278ff, #bd00ff);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.taha-home .banner .text .box h1 img {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  animation: bg 3s infinite;
  z-index: -1;
  transform: translateY(50%);
}

.taha-home .banner .text .box p {
  font-size: 2em;
  color: var(--text-color);
}

.taha-home .banner .image .box {
  height: 70%;
  /* background-color: red; */
  position: relative;
  z-index: 1;
}

.taha-home .banner .image .box .back-banner {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 767px) {
  .taha-home .banner {
    flex-direction: column;
    justify-content: space-between;
  }

  .taha-home .banner .image,
  .taha-home .banner .text {
    width: 100%;
  }

  .taha-home .banner .image .box {
    height: 100%;
  }

  .taha-home .banner .text .box h1,
  .taha-home .banner .text .box p {
    font-size: 1.5em;
  }

  .atom-one {
    width: 60px;
    height: 60px;
  }
}
.taha-home + section {
  position: relative;
  z-index: 1;
  overflow-x: hidden;
  margin-bottom: 25px;
}

.taha-home + section .back-circle-one,
.taha-home + section .back-circle-tow {
  position: absolute;
  z-index: -1;
}

.taha-home + section .back-circle-one {
  top: 0;
  right: 0;
}

.taha-home + section .back-circle-tow {
  bottom: 0;
  left: 0;
}

/* start courses section */
.taha-courses {
  padding: 3em 0;
}
.taha-courses .container .course-filter {
  padding: 15px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.taha-courses .container .course-filter li {
  padding: 10px;
  border-radius: 24px;
  text-align: center;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.5s;
  margin-left: 15px;
}
@media (max-width: 767px) {
  .taha-courses .container .row {
    justify-content: center;
  }
  .taha-courses .container .course-filter {
    flex-direction: column;
  }
  .taha-courses .container .course-filter li {
    width: 100%;
    margin: 0 0 10px 0;
  }
}
.taha-courses .container .course-filter li.active {
  background-color: #0278ff !important;
  color: #fff;
}
.taha-courses .container .row > div {
  all: unset;
  border-radius: 24px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: block;
}
.taha-courses .container .row > div .image {
  height: 200px;
}
.taha-courses .container .row > div .body {
  padding: 15px 10px 5px 10px;
}
.taha-courses .container .row > div .body .name-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.taha-courses .container .row > div .body .name-price h6 {
  font-weight: bold;
}
.taha-courses .container .row > div .body .name-price p {
  font-size: 15px;
}
.taha-courses .container .row > div .body p {
  font-size: 12px;
}
.taha-courses .container .row > div .content {
  position: relative;
  padding: 15px 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.taha-courses .container .row > div .content::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  height: 1px;
  background-color: #ecf0f1;
}
.taha-courses .container .row > div .content p {
  margin: 0;
  font-size: 12px;
  color: var(--text-color);
  width: 45%;
}
.taha-courses .container .row > div .content p i {
  margin-left: 10px;
}
.taha-courses .container .row > div .course-footer {
  padding: 10px;
}
.taha-courses .container .row > div .course-footer a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border-radius: 24px;
  background-color: #00bd99;
  color: #fff;
  transition: all 0.5s;
  font-weight: bold;
}
.taha-courses .container .row > div .course-footer a > div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  color: #00bd99;
  background-color: #fff;
  transition: all 0.5s;
}
.taha-courses .container .row > div .course-footer a > div i {
  color: #00bd99;
}
.taha-courses .container .row > div .course-footer a:hover {
  background-color: #fff;
  color: #00bd99;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}
.taha-courses .container .row > div .course-footer a:hover > div {
  background-color: #00bd99;
  color: #fff !important;
}
.taha-courses .container .row > div .course-footer a:hover > div i {
  color: #fff !important;
}

/* start courses */

/* start courses section */
.taha-courses {
  padding: 3em 0;
}
.taha-courses .container .course-filter {
  padding: 15px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.taha-courses .container .course-filter li {
  padding: 10px;
  border-radius: 24px;
  text-align: center;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.5s;
  margin-left: 15px;
}
@media (max-width: 767px) {
  .taha-courses .container .row {
    justify-content: center;
  }
  .taha-courses .container .course-filter {
    flex-direction: column;
  }
  .taha-courses .container .course-filter li {
    width: 100%;
    margin: 0 0 10px 0;
  }
}
.taha-courses .container .course-filter li.active {
  background-color: #0278ff !important;
  color: #fff;
}
.taha-courses .container .row > div {
  all: unset;
  border-radius: 24px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: block;
}
.taha-courses .container .row > div .image {
  height: 200px;
}
.taha-courses .container .row > div .body {
  padding: 15px 10px 5px 10px;
}
.taha-courses .container .row > div .body .name-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.taha-courses .container .row > div .body .name-price h6 {
  font-weight: bold;
}
.taha-courses .container .row > div .body .name-price p {
  font-size: 15px;
}
.taha-courses .container .row > div .body p {
  font-size: 12px;
}
.taha-courses .container .row > div .content {
  position: relative;
  padding: 15px 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.taha-courses .container .row > div .content::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 50%;
  height: 1px;
  background-color: #ecf0f1;
}
.taha-courses .container .row > div .content p {
  margin: 0;
  font-size: 12px;
  color: var(--text-color);
  width: 45%;
}
.taha-courses .container .row > div .content p i {
  margin-left: 10px;
}
.taha-courses .container .row > div .course-footer {
  padding: 10px;
}
.taha-courses .container .row > div .course-footer a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border-radius: 24px;
  background-color: #00bd99;
  color: #fff;
  transition: all 0.5s;
  font-weight: bold;
}
.taha-courses .container .row > div .course-footer a > div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  color: #00bd99;
  background-color: #fff;
  transition: all 0.5s;
}
.taha-courses .container .row > div .course-footer a > div i {
  color: #00bd99;
}
.taha-courses .container .row > div .course-footer a:hover {
  background-color: #fff;
  color: #00bd99;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}
.taha-courses .container .row > div .course-footer a:hover > div {
  background-color: #00bd99;
  color: #fff !important;
}
.taha-courses .container .row > div .course-footer a:hover > div i {
  color: #fff !important;
}
/* start best plateforms */
.best-plateforms .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.best-plateforms .content > a {
  display: block !important;
  width: calc(90% / 3);
  text-align: center;
  /* padding: 0 !important; */
}
.best-plateforms .content > a .image {
  height: 350px;
}
@media (max-width: 767px) {
  .best-plateforms .content {
    flex-direction: column;
  }
  .best-plateforms .content > a {
    margin-bottom: 15px;
    width: 100%;
  }
}

/* start about section */
.taha-about .image {
  position: relative;
  z-index: 1;
  width: 40%;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  margin-bottom: 4em;
}

.taha-about .image .circle {
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  height: 80%;
}

.taha-about .image .light {
  position: absolute;
  z-index: 2;
  font-size: 5em;
}
.taha-about .about-text {
  width: 100%;
  text-align: start;
}

.taha-about .about-text h5.about-name {
  background: linear-gradient(#0278ff, #bd00ff);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  margin-bottom: 15px;
}

.taha-about .about-boxs {
  padding: 3em 0;
}

.taha-about .about-boxs > div {
  display: flex;
  align-items: center;
  padding: 15px 10px;
  border-radius: 15px;
  background-color: #fff;
  color: var(--text-color);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  font-size: 1.3em;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: all 0.5s;
}
.taha-about .about-boxs > div i {
  color: #0278ff;
  margin-left: 10px;
}

@font-face {
  font-family: alibon;
  src: url(../../../assets/alibon-font/AlibonRegular-OVa0O.ttf);
}

svg.svg-animation {
  /* font-family: alibon; */
  width: 100%;
  height: 100%;
}
svg.svg-animation text {
  animation: stroke 7s infinite alternate;
  stroke-width: 2;
  stroke: #365fa0;
  font-size: 5em;
}
@keyframes stroke {
  0% {
    fill: rgba(0, 0, 0, 1);
    stroke: rgba(0, 0, 0, 1);
    stroke-dashoffset: 25%;
    stroke-dasharray: 0 50%;
    stroke-width: 2;
  }
  70% {
    fill: rgba(0, 0, 0, 0);
    stroke: rgba(0, 0, 0, 1);
  }
  80% {
    fill: rgba(0, 0, 0, 0);
    stroke: rgba(0, 0, 0, 1);
    stroke-width: 3;
  }
  100% {
    fill: rgba(0, 0, 0, 1);
    stroke: rgba(0, 0, 0, 1);
    stroke-dashoffset: -25%;
    stroke-dasharray: 50% 0;
    stroke-width: 0;
  }
}
/* button back to top */
.button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgb(20, 20, 20);
  border: none;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 0px 4px rgba(180, 160, 255, 0.253);
  cursor: pointer;
  transition-duration: 0.3s;
  overflow: hidden;
  position: fixed;
  bottom: 10px;
  right:20px;
  z-index: 2000;
  display: none;
}

.svgIcon {
  width: 12px;
  transition-duration: 0.3s;
}

.svgIcon path {
  fill: white;
}

.button:hover {
  width: 140px;
  border-radius: 50px;
  transition-duration: 0.3s;
  background-color: rgb(218, 9, 68);
  align-items: center;
}

.button:hover .svgIcon {
  /* width: 20px; */
  transition-duration: 0.3s;
  transform: translateY(-200%);
}

.button::before {
  position: absolute;
  bottom: -20px;
  content: "الصعود الى الأعلى ";
  color: white;
  /* transition-duration: .3s; */
  font-size: 0px;
}

.button:hover::before {
  font-size: 13px;
  opacity: 1;
  bottom: unset;
  /* transform: translateY(-30px); */
  transition-duration: 0.3s;
}

/* responsive of mo salah */
@media screen and (max-width: 480px) {
  .home {
    flex-direction: column-reverse !important;
    height: max-content !important;
    height: max-content !important;
    padding-bottom: 30px !important;
  }
  .home .home-right {
    width: 90% !important;
    margin: auto !important;
    line-height: 1.9;
    text-align: center;
  }
  .home .atoms {
    top: 10px !important;
  }
  .home .home-right h1 {
    font-weight: 650 !important;
    font-size: 40px !important;
  }
  .home .home-left {
    width: 75% !important;
    margin: 0px auto !important;
  }
  .home .home-left img {
    width: 100% !important;
    margin: auto;
  }
  .about {
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 10px !important;
  }
  .about-right h1 {
    line-height: 1.8 !important;
  }
  .about-right {
    width: 90% !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 0px !important;
  }
  .about-right .prp {
    padding: 20px !important;
    width: 90% !important;
    margin: auto !important;
  }
  .about-right h2 {
    font-size: 30px !important;
    line-height: 1.8 !important;
  }
  .about .about-left {
    width: 60% !important;
  }
  .about-right .prp {
    width: 100% !important;
    margin: auto !important;
    margin-top: 40px !important;
  }
  .about-right .onee {
    width: 100% !important;
    margin: auto !important;
  }
  .prop {
    flex-direction: column;
    justify-content: center!important;
    align-items: center!important;
    gap: 50px !important;
    flex-wrap: wrap;
  }.prop .prop-left{
    width: 90%!important;
    margin: auto!important;
  }
  .prop .prp {
    width: 20% !important;
    text-align: center!important;
  }.advent{
    flex-direction: column!important;
  }
  .advert {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 90% !important;
    margin: auto;
  }
  .advert-left {
    width: 90% !important;
    margin: auto !important;
  }
  .advert-right {
    width: 90% !important;
    margin: auto !important;
    margin-top: 30px !important;
  }
  .advert-right .adv {
    width: 100% !important;
  }
  .mobile-menu-links {
    padding-top: 30px !important;
    background-color: white !important;
    z-index: 30;
  }
  .left-image img {
    width: 100% !important;
  }
  .login-section {
    width: 80% !important;
  }
}
@media screen and (min-width: 481px) and (max-width: 769px) {
  .home {
    flex-direction: column-reverse !important;
    height: max-content !important;
    overflow: hidden !important;
    padding-bottom: 30px !important;
  }
  .home .home-right {
    width: 90% !important;
    text-align: center !important;
    margin: auto !important;
    margin-top: 90px !important;
    line-height: 1.9;
  }
  .home .home-right h1 {
    font-weight: 650 !important;
    font-size: 45px !important;
  }
  .home-left {
    width: 60% !important;
    margin: 0px auto !important;
  }
  .home .home-left img {
    width: 100% !important;
    margin: auto;
  }
  .about {
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 10px !important;
  }
  .about-right h1 {
    line-height: 1.8 !important;
  }
  .about-right {
    width: 90% !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-right: 0px !important;
  }
  .about-right .prp {
    padding: 20px !important;
    width: 90% !important;
    margin: auto !important;
  }
  .about-right h2 {
    font-size: 30px !important;
    line-height: 1.8 !important;
  }
  .about .about-left {
    width: 50% !important;
  }
  .about-right .prp {
    width: 45% !important;
    margin: auto !important;
    margin-top: 40px !important;
  }
  .about-right .onee {
    width: 100% !important;
    margin: auto !important;
  }
  .prop {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px !important;
    flex-wrap: wrap;
  }
  .prop .prp {
    width: 20% !important;
  }
  .left-image img {
    width: 100% !important;
  }
  .login-section {
    width: 70% !important;
  }
}
/* some edition of abdelmagied */
.course:hover {
  background-color: rgb(223, 22, 65) !important;
  transition: 0.5s !important;
  color: white !important;
}
.course a {
  background-color: rgb(213, 7, 52) !important;
  transition: 0.5s !important;
  color: white !important;
}
.course a:hover {
  background-color: rgb(232, 84, 116) !important;
  transition: 0.5s !important;
  color: white !important;
}
.course:hover a {
  background-color: rgb(232, 84, 116) !important;
  transition: 0.5s !important;
  color: white !important;
}
.image {
  border-color: red !important ;
  /* background-color: #00bd99 !important; */
}
